var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "data-container" }, [
    _c(
      "div",
      {
        staticClass: "flex-row-center f-between mb16",
        staticStyle: { height: "48px" },
      },
      [
        _c("div", { staticClass: "title-header-text" }, [
          _vm._v(_vm._s(_vm.$t("dataCentre.dataCentre"))),
        ]),
        _c("div", { staticClass: "flex-row-center" }, [
          _c(
            "span",
            {
              staticClass: "data-refresh color-blue",
              on: { click: _vm.getData },
            },
            [
              _c("i", { staticClass: "el-icon-refresh" }),
              _vm._v(" " + _vm._s(_vm.$t("dataCentre.syncData")) + " "),
            ]
          ),
          _vm.dataCentreData.updateTime ? _c("span", [_vm._v("|")]) : _vm._e(),
          _vm.dataCentreData.updateTime
            ? _c("span", { staticClass: "data-time" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("dataCentre.lastSyncTime")) +
                    "：" +
                    _vm._s(_vm.dataCentreData.updateTime) +
                    " "
                ),
              ])
            : _vm._e(),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "data-card",
      },
      [
        _c("div", { staticClass: "flex-row-center mb16 w-100" }, [
          _c("div", { staticClass: "blue-line" }),
          _c("div", { staticClass: "data-title f-grow" }, [
            _vm._v(_vm._s(_vm.$t("dataCentre.dataOverview"))),
          ]),
          _c(
            "div",
            { staticClass: "f-shrink" },
            [
              _vm._v(" " + _vm._s(_vm.$t("dataCentre.platform")) + "： "),
              _c(
                "el-select",
                {
                  staticClass: "no-border-select",
                  staticStyle: { width: "100px" },
                  attrs: { placeholder: _vm.$t("tip.select") },
                  on: { change: _vm.platformChange },
                  model: {
                    value: _vm.queryParams.platform,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "platform", $$v)
                    },
                    expression: "queryParams.platform",
                  },
                },
                _vm._l(_vm.platformFilters, function (dict) {
                  return _c("el-option", {
                    key: dict.value,
                    attrs: { label: dict.text, value: dict.value },
                  })
                }),
                1
              ),
              _vm._v(" " + _vm._s(_vm.$t("dataCentre.account")) + "： "),
              _c(
                "el-select",
                {
                  staticClass: "no-border-select",
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: _vm.$t("tip.select") },
                  on: { change: _vm.getData },
                  model: {
                    value: _vm.queryParams.socialId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "socialId", $$v)
                    },
                    expression: "queryParams.socialId",
                  },
                },
                _vm._l(_vm.accountFilters, function (item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { label: item.displayName, value: item.id },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-row-center" },
                        [
                          _c("el-avatar", {
                            attrs: { size: 28, src: item.userImage },
                          }),
                          _c("div", { staticClass: "ml5" }, [
                            _vm._v(_vm._s(item.displayName)),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" " + _vm._s(_vm.$t("dataCentre.date")) + "： "),
              _c(
                "el-select",
                {
                  staticClass: "no-border-select",
                  staticStyle: { width: "100px" },
                  attrs: { placeholder: _vm.$t("tip.select") },
                  on: { change: _vm.getData },
                  model: {
                    value: _vm.queryParams.date,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "date", $$v)
                    },
                    expression: "queryParams.date",
                  },
                },
                _vm._l(_vm.dateFilter, function (dict) {
                  return _c("el-option", {
                    key: dict.date,
                    attrs: { label: dict.title, value: dict.date },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "el-row",
          { attrs: { gutter: 16 } },
          _vm._l(_vm.dataList, function (item, index) {
            return _c("el-col", { key: index, attrs: { span: 4 } }, [
              _c(
                "div",
                {
                  staticClass: "data-total-normal",
                  class: [
                    _vm.getLabelClass(index),
                    item.select
                      ? _vm.getLabelSelectClass(index)
                      : "data-total-normal",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.clickData(item, index)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex-row-center" }, [
                    _c(
                      "span",
                      {
                        staticClass: "tab-title",
                        class: item.select ? "tab-title-select" : "",
                      },
                      [_vm._v(_vm._s(item.title))]
                    ),
                  ]),
                  _c("div", { staticClass: "des" }, [
                    _vm._v(_vm._s(item.num || 0)),
                  ]),
                ]
              ),
            ])
          }),
          1
        ),
        _c("div", { staticClass: "flex-row-center mt20 tab-title" }, [
          _c("div", { staticStyle: { width: "65%" } }, [
            _vm._v(
              _vm._s(_vm.currentData.title) + _vm._s(_vm.$t("dataCentre.trend"))
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.currentData.socialProportion &&
                    _vm.currentData.socialProportion.length > 0,
                  expression:
                    "currentData.socialProportion&&currentData.socialProportion.length > 0",
                },
              ],
              staticStyle: { width: "35%" },
            },
            [_vm._v(_vm._s(_vm.$t("dataCentre.platformDataRatio")))]
          ),
        ]),
        _c(
          "div",
          { staticClass: "w-100 mt10" },
          [
            _vm.currentData.echartsData &&
            _vm.currentData.echartsData.length > 0
              ? _c(
                  "div",
                  { staticClass: "flex-row w-100" },
                  [
                    _c("data-echarts", {
                      ref: "trendEcharts",
                      staticClass: "mb10 bg-white",
                      attrs: {
                        width: "65%",
                        height: "250px",
                        chartData: _vm.currentData.echartsData || [],
                        dateList: _vm.dateList,
                      },
                    }),
                    _c("pie-echarts", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.currentData.socialProportion &&
                            _vm.currentData.socialProportion.length > 0,
                          expression:
                            "currentData.socialProportion&&currentData.socialProportion.length > 0",
                        },
                      ],
                      ref: "proportionEcharts",
                      staticClass: "mb10 bg-white",
                      attrs: {
                        width: "35%",
                        height: "250px",
                        piedata: _vm.currentData.socialProportion || [],
                      },
                    }),
                  ],
                  1
                )
              : _c("el-empty", {
                  attrs: { slot: "empty", "image-size": 200 },
                  slot: "empty",
                }),
          ],
          1
        ),
      ],
      1
    ),
    _vm.postList.length > 0
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "data-card",
          },
          [
            _c("div", { staticClass: "flex-row-center mb16 f-between" }, [
              _c("div", { staticClass: "flex-row-center" }, [
                _c("div", { staticClass: "blue-line" }),
                _c("div", { staticClass: "data-title" }, [
                  _vm._v(_vm._s(_vm.$t("dataCentre.contentAnalysis"))),
                ]),
              ]),
              _vm.pageNumbers.length > 1
                ? _c(
                    "div",
                    { staticClass: "flex-row-center f-shrink" },
                    _vm._l(_vm.pageNumbers, function (page) {
                      return _c(
                        "span",
                        {
                          key: page,
                          staticClass: "page-button",
                          class: { active: _vm.currentPage === page },
                          on: {
                            click: function ($event) {
                              return _vm.changePage(page)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(page) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _vm._l(_vm.currentPageData, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "data-content flex-row-center" },
                [
                  _c("video", {
                    staticClass: "data-video-cover f-shrink",
                    attrs: { src: item.url, alt: "video" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "flex-column f-grow",
                      staticStyle: { overflow: "hidden" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex-row-center f-between",
                          staticStyle: { overflow: "hidden" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "single-line-ellipsis f-grow",
                              staticStyle: { overflow: "hidden" },
                            },
                            [_vm._v(" " + _vm._s(item.postContent) + " ")]
                          ),
                          _c("div", { staticClass: "f-shrink ml16" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.parseTime(item.publishTime)) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex-row-center f-between mt16" },
                        [
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("dataCentre.play"))),
                            _c("span", { staticClass: "content-num" }, [
                              _vm._v(
                                _vm._s(
                                  item.views && item.views > 0 ? item.views : 0
                                )
                              ),
                            ]),
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("dataCentre.like"))),
                            _c("span", { staticClass: "content-num" }, [
                              _vm._v(
                                _vm._s(
                                  item.likes && item.likes > 0 ? item.likes : 0
                                )
                              ),
                            ]),
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("dataCentre.comment"))),
                            _c("span", { staticClass: "content-num" }, [
                              _vm._v(
                                _vm._s(
                                  item.comments && item.comments > 0
                                    ? item.comments
                                    : 0
                                )
                              ),
                            ]),
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("dataCentre.share"))),
                            _c("span", { staticClass: "content-num" }, [
                              _vm._v(
                                _vm._s(
                                  item.shares && item.shares > 0
                                    ? item.shares
                                    : 0
                                )
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "data-card",
      },
      [
        _c("div", { staticClass: "flex-row-center mb16" }, [
          _c("div", { staticClass: "blue-line" }),
          _c("div", { staticClass: "data-title" }, [
            _vm._v(_vm._s(_vm.$t("dataCentre.fanAnalysis"))),
          ]),
        ]),
        _c(
          "el-row",
          { attrs: { gutter: 16 } },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "fan-analysis" },
                [
                  _c("span", { staticClass: "tab-title" }, [
                    _vm._v(_vm._s(_vm.$t("dataCentre.genderDistribution"))),
                  ]),
                  _vm.sixData.length > 0
                    ? _c("pie-echarts", {
                        ref: "sexEcharts",
                        staticClass: "mb10",
                        attrs: { piedata: _vm.sixData || [] },
                      })
                    : _c(
                        "div",
                        { staticClass: "no-data flex-column-center f-center" },
                        [
                          _c("svg-icon", {
                            staticClass: "no-data-icon",
                            attrs: { "icon-class": "data_center_no_data" },
                          }),
                          _c("div", { staticClass: "no-data-text" }, [
                            _vm._v(_vm._s(_vm.$t("dataCentre.noData"))),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "fan-analysis" },
                [
                  _c("span", { staticClass: "tab-title" }, [
                    _vm._v(_vm._s(_vm.$t("dataCentre.ageDistribution"))),
                  ]),
                  _vm.ageChartData.length > 0
                    ? _c("data-echarts", {
                        ref: "ageEcharts",
                        attrs: {
                          width: "100%",
                          height: "250px",
                          chartType: "bar",
                          chartData: _vm.ageChartData,
                          dateList: _vm.ageList,
                        },
                      })
                    : _c(
                        "div",
                        { staticClass: "no-data flex-column-center f-center" },
                        [
                          _c("svg-icon", {
                            staticClass: "no-data-icon",
                            attrs: { "icon-class": "data_center_no_data" },
                          }),
                          _c("div", { staticClass: "no-data-text" }, [
                            _vm._v(_vm._s(_vm.$t("dataCentre.noData"))),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "fan-analysis" },
                [
                  _c("span", { staticClass: "tab-title" }, [
                    _vm._v(_vm._s(_vm.$t("dataCentre.fanGrowth"))),
                  ]),
                  _vm.fansChartData.length > 0
                    ? _c("data-echarts", {
                        ref: "fansEcharts",
                        attrs: {
                          width: "100%",
                          height: "250px",
                          chartType: "bar",
                          chartData: _vm.fansChartData,
                          dateList: _vm.dateList,
                        },
                      })
                    : _c(
                        "div",
                        { staticClass: "no-data  flex-column-center f-center" },
                        [
                          _c("svg-icon", {
                            staticClass: "no-data-icon",
                            attrs: { "icon-class": "data_center_no_data" },
                          }),
                          _c("div", { staticClass: "no-data-text" }, [
                            _vm._v(_vm._s(_vm.$t("dataCentre.noData"))),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "fan-analysis" }, [
                _c("span", { staticClass: "tab-title" }, [
                  _vm._v(_vm._s(_vm.$t("dataCentre.activeDistribution"))),
                ]),
                _c(
                  "div",
                  { staticClass: "no-data flex-column-center f-center" },
                  [
                    _c("svg-icon", {
                      staticClass: "no-data-icon",
                      attrs: { "icon-class": "data_center_no_data" },
                    }),
                    _c("div", { staticClass: "no-data-text" }, [
                      _vm._v(_vm._s(_vm.$t("dataCentre.noData"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("el-col", { attrs: { span: 24 } }, [
              _c(
                "div",
                { staticClass: "fan-analysis" },
                [
                  _c("span", { staticClass: "tab-title" }, [
                    _vm._v(_vm._s(_vm.$t("dataCentre.regionDistribution"))),
                  ]),
                  _vm.mapData.length > 0
                    ? _c("map-echarts", {
                        ref: "mapEcharts",
                        attrs: { mapData: _vm.mapData },
                      })
                    : _c(
                        "div",
                        { staticClass: "no-data flex-column-center f-center" },
                        [
                          _c("svg-icon", {
                            staticClass: "no-data-icon",
                            attrs: { "icon-class": "data_center_no_data" },
                          }),
                          _c("div", { staticClass: "no-data-text" }, [
                            _vm._v(_vm._s(_vm.$t("dataCentre.noData"))),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }