<template>
  <div
    v-loading="loading"
    class="app-container"
    style="overflow-x: outo;overflow-y: hidden;"
  >
    <div class="video-editor-title flex-row-center f-between mb16">
      <div class="flex-row-center">{{ $t('workbench.videoProduction') }}
        <el-popover
          placement="left-start"
          width="518"
          trigger="click"
        >
          <div class="popover-title">{{ $t('guidance.vidEditIntro') }}</div>
          <div class="popover-content">{{ $t('guidance.vidEditInstructions') }} </div>
          <video
            controls
            :src="videoSrc"
            class="video-player"
            ref="videoPlayer"
          ></video>
          <div slot="reference">
            <el-tooltip :content="$t('guidance.guide')">
              <i class="iconfont icon-zhushi ml8 pointer" />
            </el-tooltip>
          </div>
        </el-popover>
      </div>
      <div
        class="flex-row-center pointer back-list"
        @click="goBack"
      >
        <i class="iconfont icon-fanhui mr8 " />
        {{ $t('backList') }}
      </div>
    </div>
    <div class="flex-row">
      <div class="ve-step editor-scroll f-shrink">
        <StepProgressBar
          :steps="steps"
          :activeStep="currentStep"
          @update:activeStep="updateStep"
        />
      </div>
      <div class="f-grow flex-column ">
        <div class="f-grow middle-right-container">
          <div
            class="flex-row"
            v-show="currentStep===0"
          >
            <div class="f-grow editor-scroll base-info-container">
              <div class="videoTitle basic-info-title flex-row-center">
                <svg-icon
                  icon-class="text_bg"
                  class="title-bg-svg"
                />
                <span class="ml16">
                  {{ $t('videoEditor.info') }}
                </span>
              </div>
              <div
                class="set-des"
                style="margin: 8px 16px;"
              >{{$t('videoSetting.videoNameLocation')}}</div>
              <el-form
                :model="totalInfo"
                :rules="rules"
                ref="totalInfo"
                label-width="120px"
                label-position="top"
              >
                <el-form-item
                  :label="$t('videoEditor.videoName')"
                  prop="taskName"
                >
                  <el-input
                    v-model="totalInfo.taskName"
                    maxlength="50"
                    show-word-limit
                    :placeholder="$t('tip.input')"
                    style="width:340px"
                    @input="updatedMixing(totalInfo)"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('videoEditor.videoRatio')"
                  prop="aspectRatio"
                >
                  <el-radio-group v-model="totalInfo.aspectRatio">
                    <el-radio
                      label="9:16"
                      border
                    >9:16</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  :label="$t('aiSet.location')"
                  prop="productTypeId"
                >
                  <el-select
                    v-model="totalInfo.productTypeId"
                    :placeholder="$t('file.tipSelectFolder')"
                    @change="updatedMixing(totalInfo)"
                    style="width: 340px;"
                  >
                    <el-option
                      v-for="item in productTypelist"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item :label="$t('videoEditor.selectModel')" prop="productTypeId">
                      <el-select v-model="totalInfo.productTypeId" :placeholder="$t('file.tipSelectFolder')"
                                 @change="updatedMixing(totalInfo)" style="width: 340px;">
                        <el-option v-for="item in productTypelist" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('videoEditor.keywordDescription')" prop="productTypeId">
                      <el-input v-model="totalInfo.productTypeId" maxlength="50" show-word-limit
                                :placeholder="$t('tip.input')" style="width:340px"
                                @input="updatedMixing(totalInfo)"></el-input>
                    </el-form-item> -->
              </el-form>
            </div>
            <div class="editor-scroll mix-right f-shrink">
              <MixingSettings
                :value="totalInfo"
                ref="mixingSettings"
                @item-updated="updatedMixing"
              ></MixingSettings>
            </div>

            <div
              class="editor-scroll f-shrink"
              style="width: 293px;padding: 16px;"
            >
              <div class="videoTitle">{{$t('videoEditor.effectPreview')}}</div>
              <div class="set-des">{{$t('videoEditor.viewConfigEffect')}}</div>
              <div
                class="preview"
                :style="previewStyle"
              >
                <img
                  v-if="previewImg"
                  :src="previewImg"
                  class="preview-img"
                />
                <video
                  v-if="previewVideo && !previewImg "
                  :src="previewVideo"
                  class="preview-img"
                />
                <img
                  v-if="totalInfo.logoTrackClipsBo && totalInfo.logoTrackClipsBo.mediaUrl"
                  :src="totalInfo.logoTrackClipsBo.mediaUrl"
                  :style="logoStyle"
                  class="logo-image"
                />
                <p :style="mixPreviewStyle">{{ }}</p>
              </div>
            </div>
          </div>
          <!-- v-show="currentStep-1 == index" -->
          <div v-show="currentStep > 0">
            <div
              v-for="(item, index) in totalInfo.videoSceneBos"
              :key="index"
            >
              <task-item
                v-if="currentStep-1 == index"
                :value="item"
                :effectColorStyleList="effectColorStyleList"
                :setupSync.sync="totalInfo.setupSync"
                @item-updated="handleItemUpdated(index, $event)"
                @remove="remove(index)"
                ref="taskItems"
              />
            </div>
          </div>
        </div>
        <div
          class="flex-center mt10"
          v-if="currentStep===0"
        >
          <el-button
            class="gradient-button"
            @click="addScene"
          >{{ $t('videoEditor.addScene') }}</el-button>
          <el-button
            class="button-white"
            :loading="buttonLoading1"
            @click="saveDraft"
          >
            <div class="flex-center">
              <i class="iconfont icon-sousuotiaojianbaocun mr5"></i>
              {{ $t('videoEditor.draft') }}
            </div>
          </el-button>
        </div>
        <div
          v-else
          class="flex-center mt10"
        >
          <el-button
            class="gradient-button"
            @click="chooseMaterialbyScene"
          >{{ $t('videoEditor.importByScene') }}
          </el-button>
          <el-button
            class="gradient-button"
            @click="addScene"
          >{{ $t('videoEditor.addScene') }}</el-button>
          <el-button
            class="delete-button"
            @click="remove(currentStep-1)"
          >{{ $t('videoEditor.deleteScene') }}</el-button>
          <!-- <el-button type="primary" :loading="buttonLoading2" @click="createVideo(2)">
                {{ $t('videoEditor.preview') }}
              </el-button> -->
          <el-button
            class="gradient-button"
            :loading="buttonLoading3"
            @click="createVideo(1)"
          >{{$t('videoEditor.generateVideo')}}</el-button>
          <el-button
            class="button-white"
            :loading="buttonLoading1"
            @click="saveDraft"
          >
            <div class="flex-center">
              <i class="iconfont icon-sousuotiaojianbaocun mr5"></i>
              {{ $t('videoEditor.draft') }}
            </div>
          </el-button>
        </div>
      </div>
      <!-- <el-row class="f-grow ">
        <el-col :span="20">

        </el-col>
      </el-row> -->
    </div>
    <!-- <div class="flex-row">
      <div class="main-left">
        <el-card>

        </el-card>
        <task-item
          v-for="(item, index) in totalInfo.videoSceneBos"
          :key="index"
          :value="item"
          :effectColorStyleList="effectColorStyleList"
          @item-updated="handleItemUpdated(index, $event)"
          @remove="remove(index)"
          ref="taskItems"
        />
      </div>
      <el-card class="main-right">
        <MixingSettings :value="totalInfo" ref="mixingSettings" @item-updated="updatedMixing"></MixingSettings>
      </el-card>
    </div> -->
    <el-drawer
      :title="$t('videoEditor.previewResult')"
      size="65%"
      :visible.sync="drawer"
      :with-header="false"
    >
      <VideoPreview
        ref="videoPreview"
        @createTaskSuccess="createTaskSuccess"
      ></VideoPreview>
    </el-drawer>
    <material-drawer
      ref="materialDrawer"
      :visible.sync="materialDrawer"
      @selectionConfirmed="selectionConfirmed"
    />
  </div>
</template>

<script>
import TaskItem from "../../components/video/TaskItemNew.vue";
import StepProgressBar from "../../components/video/StepProgressBar.vue";
import MixingSettings from "../../components/video/MixingSettings.vue";
import VideoPreview from "./videoPreview.vue";
import {
  createAndGenerateVideoTask,
  createVideoTask,
  getVideoTask,
} from "../../api/ai/videoTask";
import { listProductType } from "../../api/ai/productType";
import { getEffectColorStyleList } from "../../api/ai/ali";
import Vue from "vue";
import MaterialDrawer from "@/components/MaterialDrawer/index.vue";

export default {
  name: "VideoEditor",
  components: {
    TaskItem,
    MixingSettings,
    VideoPreview,
    StepProgressBar,
    MaterialDrawer,
  },
  data() {
    const count = 1; // 场景名的后缀

    const videoSceneBos = [
      {
        isMute: true,
        durationType: "",
        sceneName: this.$t("videoEditor.scene") + count,
        videoTrackClipsBos: [],
        audioTrackClipsBos: {},
        titleTrackClipsBo: {},
      },
    ];
    return {
      loading: false,
      // 按钮loading
      buttonLoading1: false,
      buttonLoading2: false,
      buttonLoading3: false,
      count, //场景名的后缀
      totalInfo: {
        taskName: this.getTaskName(),
        aspectRatio: "9:16",
        productTypeId: 0,
        setupSync: false,
        videoSceneBos,
      }, //需上传的总数据
      rules: {
        taskName: [
          {
            required: true,
            message: this.$t("videoEditor.tipVideoName"),
            trigger: ["blur", "change"],
          },
        ],
        // productTypeId: [
        //   { required: true, message: this.$t('file.tipSelectFolder'), trigger: ['blur', 'change'] }
        // ],
      },
      id: "",
      addType: "add",
      productTypelist: [
        {
          id: 0,
          name: this.$t("product.product"),
        },
      ], //成片分组
      drawer: false, //预览的抽屉
      effectColorStyleList: [
        {
          effectColorStyle: "CS0001-000001",
          imgUrl:
            "https://help-static-aliyun-doc.aliyuncs.com/assets/img/zh-CN/1263923961/p712522.png",
        },
        {
          effectColorStyle: "CS0001-000002",
          imgUrl:
            "https://help-static-aliyun-doc.aliyuncs.com/assets/img/zh-CN/1263923961/p712523.png",
        },
        {
          effectColorStyle: "CS0001-000003",
          imgUrl:
            "https://help-static-aliyun-doc.aliyuncs.com/assets/img/zh-CN/1263923961/p712525.png",
        },
        {
          effectColorStyle: "CS0001-000004",
          imgUrl:
            "https://help-static-aliyun-doc.aliyuncs.com/assets/img/zh-CN/1263923961/p712526.png",
        },
        {
          effectColorStyle: "CS0001-000005",
          imgUrl:
            "https://help-static-aliyun-doc.aliyuncs.com/assets/img/zh-CN/1263923961/p712528.png",
        },
      ], //花字效果
      steps: [
        {
          title: this.$t("videoEditor.videoSettings"),
          des: this.$t("videoSetting.videoParams"),
        },
        {
          title: this.$t("videoEditor.materialScene"),
          des: this.$t("videoSetting.visualElements"),
        },
      ],
      currentStep: 0,
      videoSrc:
        "https://pub.grds.cn/web_video/%E8%A7%86%E9%A2%91%E6%B7%B7%E5%89%AA_%E6%93%8D%E4%BD%9C%E5%BC%95%E5%AF%BC.mp4",
      syncAudio: {}, // 用来保存同步的字幕设置
      syncTitle: {}, // 用来保存同步的标题设置
      materialDrawer: false, //素材抽屉
      previewSize: { width: 253, height: 450 },
      originalSize: { width: 1080, height: 1920 },
    };
  },
  computed: {
    scale() {
      return this.previewSize.height / this.originalSize.height;
    },
    positionMap() {
      return {
        "left-top": { x: 0, y: 0 },
        "center-top": { x: 0.5, y: 0 },
        "right-top": { x: 0.9, y: 0 },
        "left-bottom": { x: 0, y: 0.9 },
        "center-bottom": { x: 0.5, y: 0.9 },
        "right-bottom": { x: 0.9, y: 0.9 },
      };
    },
    logoStyle() {
      if (!this.totalInfo.logoTrackClipsBo) {
        return {};
      }
      const { x, y } = this.positionMap[
        this.totalInfo.logoTrackClipsBo.position
      ] || {
        x: 0,
        y: 0,
      };
      const computedWidth = this.totalInfo.logoTrackClipsBo.width * this.scale;
      const computedHeight =
        this.totalInfo.logoTrackClipsBo.height * this.scale;
      const margin = 10; // 留出间距
      return {
        position: "absolute",
        width: `${computedWidth}px`,
        height: `${computedHeight}px`,
        left: `${x * (this.previewSize.width - computedWidth) + margin}px`,
        top: `${y * (this.previewSize.height - computedHeight) + margin}px`,
        objectFit: "contain", // 保持比例，不拉伸
      };
    },
    mixPreviewStyle() {
      const settings = this.totalInfo || {};
      // const effect_asr = this.settings.effects && this.settings.effects.find(effect => effect.type === 'AI_ASR') || {}
      // const maxTop = 450;
      // const lines = effect_asr.position === 'top' ? 12 : effect_asr.position === 'center' ? 6 : 1

      return {
        // fontFamily: effect_asr.font || 'SimSun',
        // fontSize: `${effect_asr.fontSize / 2.84 || 16}px`,
        // letterSpacing: `${effect_asr.spacing || 0}px`,
        // color: effect_asr.fontColor || '#ffffff',
        // textShadow: `${effect_asr.outline || 0}px ${effect_asr.outline || 0}px ${effect_asr.outlineColour || '#000000'}`,
        // // opacity: (settings.opacity || 100) / 100,
        // fontStyle: (effect_asr.fontFaces || []).includes('Italic') ? 'italic' : 'normal',
        // fontWeight: (effect_asr.fontFaces || []).includes('Bold') ? 'bold' : 'normal',
        // textDecoration: (effect_asr.fontFaces || []).includes('Underline') ? 'underline' : 'none',
        // position: 'absolute',
        // top: `${Math.min(effect_asr.y * 450, maxTop)}px`,
        textAlign: "center",
        left: "50%",
        transform: "translateX(-50%)",
        overflow: "hidden", // 隐藏超出范围的文本
        textOverflow: "ellipsis", // 超出部分显示省略号
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        // '-webkit-line-clamp': lines,
        padding: "0 10px", // 调整左右边距
        width: "250px",
      };
    },
    previewImg() {
      if (
        this.totalInfo.coverUrl &&
        this.getTypeByUrl(this.totalInfo.coverUrl) === "image"
      ) {
        return this.totalInfo.coverUrl;
      } else if (
        this.totalInfo.backCoverUrl &&
        this.getTypeByUrl(this.totalInfo.backCoverUrl) === "image"
      ) {
        return this.totalInfo.backCoverUrl;
      }
      return "";
    },
    previewVideo() {
      if (
        this.totalInfo.coverUrl &&
        this.getTypeByUrl(this.totalInfo.coverUrl) === "video"
      ) {
        return this.totalInfo.coverUrl;
      } else if (
        this.totalInfo.backCoverUrl &&
        this.getTypeByUrl(this.totalInfo.backCoverUrl) === "video"
      ) {
        return this.totalInfo.backCoverUrl;
      }
      return "";
    },
    previewStyle() {
      return {
        backgroundColor: this.totalInfo.backgroundColor || "#000000",
        // backgroundImage: `url(${this.backgroundImageUrl})`,
        // backgroundSize:
        //   "contain" /* 将背景图片调整到足够大，以使其在背景区域内完全显示 */,
        // backgroundPosition: "center",
        // backgroundRepeat: "no-repeat" /* 防止背景图片重复 */,
      };
    },
    // 计算属性：过滤出当前步骤的场景
    filteredScenes() {
      return this.totalInfo.videoSceneBos.filter((item, index) => {
        return this.currentStep - 1 === index;
      });
    },
  },
  created() {
    this.getProductTypeList();
    this.getEffectColorStyleList();
    this.id = this.$route.params.id || "";
    this.addType = this.$route.query.type || "add";
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    // 返回列表
    goBack() {
      this.$tab.closeOpenPage({ path: "/videoManager/videoManager" });
    },
    chooseMaterialbyScene() {
      this.materialDrawer = true;
      this.$nextTick().then((rs) => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(1);
      });
    },
    selectionConfirmed(items) {
      items.forEach((item, index) => {
        if (!item.fileType) {
          item.fileType = this.getTypeByUrl(item.url);
        }
        delete item.id;
        delete item.type;
        // 计算目标场景的索引（从当前场景开始，依次往后）
        let sceneIndex = this.currentStep - 1 + index;
        // 确保场景足够
        while (this.totalInfo.videoSceneBos.length <= sceneIndex) {
          this.addScene(); // 如果不够场景，则添加新场景
        }
        //这个方式不行，页面不更新
        // this.totalInfo.videoSceneBos[sceneIndex].videoTrackClipsBos.push({
        //   ...item,
        //   out: item.videoLength,
        //   mediaUrl: item.url,
        //   hover: false, // 添加 hover 属性来控制蒙层显示
        //   inOutList: [{ in: 0, out: item.videoLength, checked: true }],
        // });
        // Vue.set
        const newTrack = {
          ...item,
          out: item.videoLength,
          mediaUrl: item.url,
          hover: false,
          inOutList: [{ in: 0, out: item.videoLength, checked: true }],
        };

        let scene = this.totalInfo.videoSceneBos[sceneIndex];
        // 如果 `videoTrackClipsBos` 不是数组，则先初始化
        if (!Array.isArray(scene.videoTrackClipsBos)) {
          this.$set(scene, "videoTrackClipsBos", []);
        }
        // 使用 `splice()` 替换原有数据
        scene.videoTrackClipsBos.splice(
          0,
          scene.videoTrackClipsBos.length,
          newTrack
        );
      });
      console.log("selectionConfirmed======this.totalInfo", this.totalInfo);
      this.$forceUpdate();
      this.materialDrawer = false;
    },
    //获取视频名称
    getTaskName() {
      const now = new Date();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      const name = this.$t("videoEditor.videoMixing"); // 你可以根据实际情况替换这个值

      return `${name}${month}${day}${hours}${minutes}${seconds}`;
    },
    closeWindow(msg) {
      // 关闭当前页面
      // window.opener.postMessage(msg, 'https://pub.grds.cn');// 使用 '*' 允许所有来源（仅用于测试，不推荐用于生产环境）
      // window.close();
      this.$tab.closeOpenPage({ path: "/videoManager/videoManager" });
    },
    updateStep(index) {
      this.currentStep = index;
    },
    createTaskSuccess() {
      this.drawer = false;
      this.closeWindow(this.$t("preview.tipSuccess"));
      // this.$tab.closeOpenPage({path: "/videoManager"});
    },
    //花字效果
    getEffectColorStyleList() {
      getEffectColorStyleList().then((response) => {
        this.effectColorStyleList = response.data;
      });
    },
    handleItemUpdated(index, updatedItem) {
      // 确保 `videoSceneBos` 已经存在该索引
      if (!this.totalInfo.videoSceneBos[index]) return;
      // this.$set(this.totalInfo.videoSceneBos, index, updatedItem);
      // **不要直接替换，而是更新已有对象**
      Object.assign(this.totalInfo.videoSceneBos[index], updatedItem);
      // 如果 setupSync 为 true，则同步参数
      // console.log("this.totalInfo.setupSync", this.totalInfo.setupSync);
      if (this.totalInfo.setupSync) {
        this.syncAudio = { ...updatedItem.audioTrackClipsBos };
        this.syncTitle = { ...updatedItem.titleTrackClipsBo };

        // 遍历其他场景，更新 audioTrackClipsBos 和 titleTrackClipsBo
        this.totalInfo.videoSceneBos.forEach((scene, i) => {
          if (i !== index) {
            if (scene.audioTrackClipsBos) {
              Vue.set(scene, "audioTrackClipsBos", {
                ...this.syncAudio,
                content: scene.audioTrackClipsBos.content, // 保持 content 不同
              });
            }
            if (scene.titleTrackClipsBo) {
              Vue.set(scene, "titleTrackClipsBo", {
                ...this.syncTitle,
                content: scene.titleTrackClipsBo.content,
              });
            }
          }
        });
        this.$forceUpdate();
      }
      console.log("this.totalInfo", this.totalInfo);
    },
    updatedMixing(updatedItem) {
      this.totalInfo = {
        ...this.totalInfo,
        ...updatedItem,
        taskName: updatedItem.taskName || this.totalInfo.taskName,
        productTypeId:
          updatedItem.productTypeId || this.totalInfo.productTypeId || 0,
      };
    },
    //新建场景
    addScene() {
      this.count++;
      this.steps.push({
        title: this.$t("videoEditor.materialScene"),
        des: this.$t("videoSetting.visualElements"),
      });
      // 判断 setupSync 是否为 true，若为 false，使用默认值或空对象
      let newAudio = this.totalInfo.setupSync
        ? { ...JSON.parse(JSON.stringify(this.syncAudio)), content: "" }
        : {};
      let newTitle = this.totalInfo.setupSync
        ? { ...JSON.parse(JSON.stringify(this.syncTitle)), content: "" }
        : {};
      this.totalInfo.videoSceneBos.push({
        isMute: true,
        durationType: "",
        sceneName: this.$t("videoEditor.scene") + this.count,
        videoTrackClipsBos: [],
        audioTrackClipsBos: newAudio,
        titleTrackClipsBo: newTitle,
      });
    },
    //移除场景
    remove(index) {
      if (this.totalInfo.videoSceneBos.length > 1) {
        this.$modal.confirm("确认删除当前场景吗？").then(() => {
          if (this.currentStep == this.totalInfo.videoSceneBos.length) {
            this.currentStep = this.currentStep - 1;
          }
          this.totalInfo.videoSceneBos.splice(index, 1);
          this.steps.splice(index, 1);
        });
      } else {
        this.$modal.msgError(this.$t("videoEditor.lastSceneWarning"));
      }
    },
    //成片分组列表
    getProductTypeList() {
      listProductType().then((response) => {
        this.productTypelist = response.rows;
        this.productTypelist.unshift({
          id: 0,
          name: this.$t("product.product"),
        });
      });
    },
    getDetail() {
      this.loading = true;
      getVideoTask(this.id)
        .then((res) => {
          this.totalInfo = {
            ...this.totalInfo,
            ...this.replaceKeys(res.data),
          };
          if (this.addType == "add") {
            this.totalInfo.taskName = this.getTaskName();
          }
          // 赋值封面封底类型
          if (this.totalInfo.coverUrl && !this.totalInfo.coverType) {
            this.totalInfo.coverType = this.getTypeByUrl(
              this.totalInfo.coverUrl
            );
          }
          if (this.totalInfo.backCoverUrl && !this.totalInfo.backCoverType) {
            this.totalInfo.backCoverType = this.getTypeByUrl(
              this.totalInfo.backCoverUrl
            );
          }
          // console.log(
          //   "--------getDetail-------",
          //   JSON.stringify(this.totalInfo)
          // );
          this.count =
            (this.totalInfo.videoSceneBos &&
              this.totalInfo.videoSceneBos.length) ||
            1;

          let steps = [
            {
              title: this.$t("videoEditor.videoSettings"),
              des: this.$t("videoSetting.videoParams"),
            },
          ];
          if (
            this.totalInfo.videoSceneBos &&
            this.totalInfo.videoSceneBos.length > 0
          ) {
            this.parseObject(this.totalInfo);
            // 添加每个场景的 hover 属性默认为 false
            this.totalInfo.videoSceneBos.forEach((scene) => {
              if (
                scene.videoTrackClipsBos &&
                scene.videoTrackClipsBos.length > 0
              ) {
                scene.videoTrackClipsBos.forEach((track) => {
                  this.$set(track, "hover", false);
                });
              }
              if (scene.durationType === "video") {
                this.$set(scene, "durationType", "");
              }
            });
            for (let i = 0; i < this.totalInfo.videoSceneBos.length; i++) {
              steps.push({
                title: this.$t("videoEditor.materialScene"),
                des: this.$t("videoSetting.visualElements"),
              });
            }
          } else {
            steps.push({
              title: this.$t("videoEditor.materialScene"),
              des: this.$t("videoSetting.visualElements"),
            });
          }
          this.steps = steps;
          console.log(
            "--------parseFontFaceInTotalInfo-------",
            this.totalInfo
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 解析单个字段的方法
    parseField(item, field) {
      if (item[field] && typeof item[field] === "string") {
        try {
          item[field] = JSON.parse(item[field]);
        } catch (e) {
          console.error(`解析字段 ${field} 时出错:`, e);
        }
      }
    },

    // 递归解析数据结构中的 fontFace 和 inOutList 字段
    parseObject(obj) {
      if (Array.isArray(obj)) {
        obj.forEach((item) => this.parseObject(item));
      } else if (typeof obj === "object" && obj !== null) {
        // 解析 fontFace 和 inOutList 字段
        if ("fontFace" in obj) {
          this.parseField(obj, "fontFace");
        }
        if ("inOutList" in obj) {
          this.parseField(obj, "inOutList");
        }

        // 递归解析子对象
        Object.keys(obj).forEach((key) => {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            this.parseObject(obj[key]);
          }
        });
      }
    },
    //将Vo替换成Bo，Vos替换成Bos
    replaceKeys(obj) {
      if (Array.isArray(obj)) {
        return obj.map((item) => this.replaceKeys(item));
      } else if (typeof obj === "object" && obj !== null) {
        const newObj = {};
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            let newKey = key;
            if (key === "logoTrackClipsVo") {
              newKey = "logoTrackClipsBo";
            } else if (key === "videoSceneVos") {
              newKey = "videoSceneBos";
            } else if (key === "videoTrackClipsVos") {
              newKey = "videoTrackClipsBos";
            } else if (key === "audioTrackClipsVo") {
              newKey = "audioTrackClipsBos";
            } else if (key === "audioTrackClipsVos") {
              newKey = "audioTrackClipsBos";
            } else if (key === "titleTrackClipsVo") {
              newKey = "titleTrackClipsBo";
            }
            newObj[newKey] = this.replaceKeys(obj[key]);
          }
        }
        return newObj;
      }
      return obj;
    },
    //保存草稿
    saveDraft() {
      // return
      if (!this.totalInfo.taskName) {
        this.$modal.msgError(this.$t("videoEditor.tipNameMust"));
        return;
      }

      if (this.id && this.addType === "edit") {
        //从列表进入
        this.totalInfo.id = this.id;
      } else {
        this.totalInfo.id = null;
        this.totalInfo.status = 0;
      }
      this.totalInfo.isPreview = false;
      this.coverInfo();
      if (this.checkEmojiInScenes()) {
        this.$modal.msgError(this.$t("videoEditor.tipEmoji"));
        return;
      }
      this.buttonLoading1 = true;
      console.log("--------保存草稿-------", JSON.stringify(this.totalInfo));
      createVideoTask(this.totalInfo)
        .then((res) => {
          // this.$modal.msgSuccess(this.$t('videoEditor.draftFailed'));
          // this.$tab.closeOpenPage({path: "/videoManager"});
          this.closeWindow(this.$t("videoEditor.draftFailed"));
        })
        .finally(() => {
          this.buttonLoading1 = false;
        });
    },
    //生成视频
    async createVideo(type) {
      if (type == 2) {
        const hasAudioDurationType = this.totalInfo.videoSceneBos.some(
          (scene) => scene.durationType === "audio"
        );
        if (hasAudioDurationType) {
          this.$modal.msgError(this.$t("basicInfo.audioDurationType"));
          return;
        }
      }
      // 先验证总的 totalInfo 信息
      try {
        const valid = await this.validateForm(this.$refs["totalInfo"]);
        if (valid) {
          const validationPromises = [];
          this.$refs["taskItems"].forEach((taskItem) => {
            validationPromises.push(taskItem.$refs["basicInfo"].validate());
            validationPromises.push(taskItem.$refs["subtitleInfo"].validate());
            validationPromises.push(taskItem.$refs["titleInfo"].validate());
          });
          // validationPromises.push(this.$refs.mixingSettings.validate());
          await Promise.all(validationPromises);

          if (this.id && this.addType === "edit") {
            //从列表进入
            this.totalInfo.id = this.id;
          } else {
            this.totalInfo.id = null;
            this.totalInfo.status = 0;
          }
          this.totalInfo.isPreview = true;
          this.coverInfo();
          console.log(
            "--------createVideo-totalInfo-------",
            JSON.stringify(this.totalInfo)
          );
          const validationErrors = [];

          // 1. 校验 videoSceneBos 数组是否存在并且 videoTrackClipsBos 是否有值
          if (
            !this.totalInfo.videoSceneBos ||
            !this.totalInfo.videoSceneBos.length
          ) {
            validationErrors.push("视频场景不能为空");
          } else {
            this.totalInfo.videoSceneBos.forEach((scene, index) => {
              if (
                !scene.videoTrackClipsBos ||
                scene.videoTrackClipsBos.length === 0
              ) {
                validationErrors.push(`场景 ${index + 1} 中的视频文件不能为空`);
              }

              // 2. 当 openSubtitle=true 时，校验 audioTrackClipsBos 里面的 content 是否有值
              // if (
              //   scene.openSubtitle &&
              //   (!scene.audioTrackClipsBos || !scene.audioTrackClipsBos.content)
              // ) {
              //   validationErrors.push(`场景 ${index + 1} 中的音频内容不能为空`);
              // }

              // 3. 当 openTitle=true 时，校验 titleTrackClipsBo 里面的 content 是否有值
              // if (
              //   scene.openTitle &&
              //   (!scene.titleTrackClipsBo || !scene.titleTrackClipsBo.content)
              // ) {
              //   validationErrors.push(`场景 ${index + 1} 中的标题内容不能为空`);
              // }
            });
          }

          // 如果存在校验错误，抛出异常并终止操作
          if (validationErrors.length > 0) {
            this.$modal.msgError(this.$t("videoEditor.tipInfoMust"));
            // throw new Error(validationErrors.join('\n'));
            return;
          }
          if (this.checkEmojiInScenes()) {
            this.$modal.msgError(this.$t("videoEditor.tipEmoji"));
            return;
          }
          if (type == 1) {
            this.createTask();
          } else if (type == 2) {
            this.previewVideo();
          }
        } else {
          this.$modal.msgError(this.$t("videoEditor.tipInfo"));
        }
      } catch (error) {
        console.error(error);
        this.$modal.msgError(this.$t("videoEditor.tipInfoMust"));
      }
    },
    validateForm(form, condition = true) {
      return new Promise((resolve, reject) => {
        if (condition) {
          form.validate((valid) => {
            if (valid) {
              resolve(true);
            } else {
              reject(false);
            }
          });
        } else {
          resolve(true);
        }
      });
    },
    createTask() {
      this.buttonLoading3 = true;
      createAndGenerateVideoTask(this.totalInfo)
        .then((res) => {
          this.closeWindow(this.$t("preview.tipSuccess"));
        })
        .finally(() => {
          this.buttonLoading3 = false;
        });
    },
    //预览
    previewVideo() {
      this.buttonLoading2 = true;
      createVideoTask(this.totalInfo)
        .then((res) => {
          // sessionStorage.setItem('info', JSON.stringify(res.data));
          // this.$router.push({ path: '/preview' });
          let data = res.data || [];
          if (data.length > 0) {
            let parent = data.find((item) => item.parentId == 0) || {};
            if (parent && parent.id) {
              this.id = parent.id;
            }
            let options = data.filter((item) => item.parentId != 0) || [];
            if (options.length > 0) {
              let id = data.find((item) => item.parentId == 0).id;
              this.drawer = true;
              this.$nextTick().then((rs) => {
                this.$refs.videoPreview.init(id, options);
              });
            }
          }
        })
        .finally(() => {
          this.buttonLoading2 = false;
        });
    },
    coverInfo() {
      this.updateAudioTrackClipsBos();
      this.updateTitleTrackClipsBo();
      this.checkMusic();
      this.checkLogo();
    },
    updateAudioTrackClipsBos() {
      this.totalInfo.videoSceneBos = this.totalInfo.videoSceneBos.map(
        (scene) => {
          if (
            !scene.audioTrackClipsBos ||
            Object.keys(scene.audioTrackClipsBos).length === 0 ||
            !scene.audioTrackClipsBos.content
          ) {
            return { ...scene, audioTrackClipsBos: null };
          }
          // 当 audioTrackClipsBos 不为 null 时，提取字段
          const { languageCode, voice, language } =
            scene.audioTrackClipsBos || {};
          return {
            ...scene,
            audioTrackClipsBos: { ...scene.audioTrackClipsBos }, // 保留原对象
            languageCode, // 新增字段
            language, // 新增字段
            voice, // 新增字段
          };
        }
      );
    },
    updateTitleTrackClipsBo() {
      this.totalInfo.videoSceneBos = this.totalInfo.videoSceneBos.map(
        (scene) => {
          if (
            !scene.titleTrackClipsBo ||
            Object.keys(scene.titleTrackClipsBo).length === 0 ||
            !scene.titleTrackClipsBo.content
          ) {
            return { ...scene, titleTrackClipsBo: null };
          }
          return scene;
        }
      );
    },
    checkMusic() {
      if (
        this.totalInfo.audioTrackClipsBos &&
        this.totalInfo.audioTrackClipsBos.length > 0
      ) {
        if (!this.totalInfo.audioTrackClipsBos[0].mediaUrl) {
          this.totalInfo.audioTrackClipsBos = null;
        }
      } else {
        this.totalInfo.audioTrackClipsBos = null;
      }
    },
    checkLogo() {
      if (
        this.totalInfo.logoTrackClipsBo &&
        !this.totalInfo.logoTrackClipsBo.mediaUrl
      ) {
        this.totalInfo.logoTrackClipsBo = null;
      }
    },
    // 检查每个场景中的内容是否包含 emoji
    checkEmojiInScenes() {
      let hasEmoji = false;

      for (const scene of this.totalInfo.videoSceneBos) {
        // 检查音频字幕内容
        if (scene.audioTrackClipsBos && scene.audioTrackClipsBos.content) {
          if (this.hasEmoji(scene.audioTrackClipsBos.content)) {
            console.log(
              "检测到字幕包含emoji:",
              scene.audioTrackClipsBos.content
            );
            hasEmoji = true;
            break;
          }
        }

        // 检查标题内容
        if (scene.titleTrackClipsBo && scene.titleTrackClipsBo.content) {
          if (this.hasEmoji(scene.titleTrackClipsBo.content)) {
            console.log(
              "检测到标题包含emoji:",
              scene.titleTrackClipsBo.content
            );
            hasEmoji = true;
            break;
          }
        }
      }

      return hasEmoji;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-editor-title {
  height: 40px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  .popover-title {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }
  .popover-content {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-top: 8px;
  }
  .icon-zhushi {
    color: #387dee;
    font-size: 18px;
  }
  .back-list {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    i {
      font-size: 16px;
    }
  }
}
.ve-step {
  width: 228px;
  margin-right: 16px;
}
.middle-right-container {
  border-radius: 14px;
  border: 1px solid #e5e7eb;
}

.el-form-item {
  margin-bottom: 5px;
}

::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
}

::v-deep .el-input__inner {
  border-radius: 12px;
}
.el-radio {
  border-radius: 12px;
}
.base-info-container {
  background: #f6f8fa;
  border-radius: 14px 0 0 14px;
  padding: 16px;

  .basic-info-title {
    width: 336px;
    height: 36px;
    // background: rgba(1, 86, 255, 0.05);
    position: relative;
    .title-bg-svg {
      width: 100%;
      height: 100%;
      opacity: 0.05;
      position: absolute;
    }
  }
}
.mix-right {
  width: 440px;
  padding: 16px;
  border-left: 1px solid rgba(229, 231, 235, 1);
  border-right: 1px solid rgba(229, 231, 235, 1);
}
.preview {
  position: relative;
  width: 253px;
  height: 450px;
  // background-color: #000;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-top: 20px;
  overflow: hidden; /* 确保内容不会超出预览框 */
  border-radius: 10px;
}
.logo-image {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* 关键点：保持宽高比 */
  border-radius: 5px;
  z-index: 2;
}
.preview-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}
.el-button {
  min-width: 120px;
}

.video-player {
  max-height: 210px;
}
</style>
