<template>
  <div class="data-container">
    <div
      class="flex-row-center f-between mb16"
      style="height: 48px;"
    >
      <div class="title-header-text">{{ $t('dataCentre.dataCentre') }}</div>
      <div class="flex-row-center">
        <span
          class="data-refresh color-blue"
          @click="getData"
        >
          <i class="el-icon-refresh" />
          {{ $t('dataCentre.syncData') }}
        </span>
        <span v-if="dataCentreData.updateTime">|</span>
        <span
          class="data-time"
          v-if="dataCentreData.updateTime"
        >
          {{ $t('dataCentre.lastSyncTime') }}：{{ dataCentreData.updateTime }}
        </span>
      </div>
    </div>
    <!-- 数据总览 -->
    <div
      class="data-card"
      v-loading="loading"
    >
      <div class="flex-row-center mb16 w-100">
        <div class="blue-line" />
        <div class="data-title f-grow">{{ $t('dataCentre.dataOverview') }}</div>
        <div class="f-shrink">
          {{ $t('dataCentre.platform') }}：
          <el-select
            v-model="queryParams.platform"
            :placeholder="$t('tip.select')"
            class="no-border-select"
            style="width: 100px;"
            @change="platformChange"
          >
            <el-option
              v-for="dict in platformFilters"
              :key="dict.value"
              :label="dict.text"
              :value="dict.value"
            />
          </el-select>
          {{ $t('dataCentre.account') }}：
          <el-select
            v-model="queryParams.socialId"
            :placeholder="$t('tip.select')"
            class="no-border-select"
            style="width: 150px;"
            @change="getData"
          >
            <el-option
              v-for="item in accountFilters"
              :key="item.id"
              :label="item.displayName"
              :value="item.id"
            >
              <div class="flex-row-center">
                <el-avatar
                  :size="28"
                  :src="item.userImage"
                ></el-avatar>
                <div class="ml5">{{ item.displayName }}</div>
              </div>
            </el-option>
          </el-select>
          {{ $t('dataCentre.date') }}：
          <el-select
            v-model="queryParams.date"
            :placeholder="$t('tip.select')"
            class="no-border-select"
            style="width: 100px;"
            @change="getData"
          >
            <el-option
              v-for="dict in dateFilter"
              :key="dict.date"
              :label="dict.title"
              :value="dict.date"
            />
          </el-select>
          <!-- <span
            class="data-refresh color-blue ml5"
            @click="getData"
          >
            <i class="el-icon-refresh" />
            {{ $t('dataCentre.syncData') }}
          </span> -->
        </div>
      </div>
      <el-row :gutter="16">
        <el-col
          :span="4"
          v-for=" (item, index) in dataList"
          :key="index"
        >
          <div
            class="data-total-normal"
            @click="clickData(item, index)"
            :class="[getLabelClass(index), item.select ? getLabelSelectClass(index) : 'data-total-normal']"
          >
            <div class="flex-row-center">
              <span
                class="tab-title"
                :class="item.select ? 'tab-title-select' : ''"
              >{{ item.title }}</span>
            </div>
            <div class="des">{{ item.num || 0 }}</div>
          </div>
        </el-col>
      </el-row>
      <div class="flex-row-center mt20 tab-title">
        <div style="width: 65%">{{ currentData.title }}{{ $t('dataCentre.trend') }}</div>
        <div
          style="width: 35%"
          v-show="currentData.socialProportion&&currentData.socialProportion.length > 0"
        >{{ $t('dataCentre.platformDataRatio') }}</div>
      </div>
      <div class="w-100 mt10">
        <div
          v-if="currentData.echartsData&&currentData.echartsData.length > 0"
          class="flex-row w-100"
        >
          <data-echarts
            ref="trendEcharts"
            width="65%"
            height="250px"
            :chartData="currentData.echartsData||[]"
            :dateList="dateList"
            class="mb10 bg-white"
          />
          <pie-echarts
            v-show="currentData.socialProportion&&currentData.socialProportion.length > 0"
            ref="proportionEcharts"
            width="35%"
            height="250px"
            :piedata="currentData.socialProportion||[]"
            class="mb10 bg-white"
          />
        </div>
        <el-empty
          v-else
          :image-size="200"
          slot="empty"
        ></el-empty>
      </div>
    </div>
    <!-- 内容分析 -->
    <div
      class="data-card"
      v-if="postList.length > 0"
      v-loading="loading"
    >
      <div class="flex-row-center mb16 f-between">
        <div class="flex-row-center">
          <div class="blue-line" />
          <div class="data-title">{{ $t('dataCentre.contentAnalysis') }}</div>
        </div>
        <div
          class="flex-row-center f-shrink"
          v-if="pageNumbers.length > 1"
        >
          <span
            v-for="page in pageNumbers"
            :key="page"
            :class="{ active: currentPage === page }"
            @click="changePage(page)"
            class="page-button"
          >
            {{ page }}
          </span>
        </div>
      </div>
      <div
        v-for="(item, index) in currentPageData"
        class="data-content flex-row-center"
        :key="index"
      >
        <video
          :src="item.url"
          alt="video"
          class="data-video-cover f-shrink"
        />
        <div
          class="flex-column f-grow"
          style="overflow: hidden; "
        >
          <div
            class="flex-row-center f-between"
            style="overflow: hidden; "
          >
            <div
              class="single-line-ellipsis f-grow"
              style="overflow: hidden; "
            >
              {{ item.postContent }}
              <!-- <span class="content-topic">
                {{ item.topic }}
              </span> -->
            </div>
            <div class="f-shrink ml16">
              {{ parseTime(item.publishTime) }}
            </div>
          </div>
          <div class="flex-row-center f-between mt16">
            <div>{{ $t('dataCentre.play') }}<span class="content-num">{{ item.views && item.views > 0 ? item.views : 0 }}</span></div>
            <div>{{ $t('dataCentre.like') }}<span class="content-num">{{ item.likes && item.likes > 0 ? item.likes : 0  }}</span></div>
            <div>{{ $t('dataCentre.comment') }}<span class="content-num">{{ item.comments && item.comments > 0 ? item.comments : 0  }}</span></div>
            <div>{{ $t('dataCentre.share') }}<span class="content-num">{{ item.shares && item.shares > 0 ? item.shares : 0  }}</span></div>
            <!-- <div>粉丝<span class="content-num">{{ item.fans }}</span></div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- v-if="sixData.length > 0||ageChartData.length > 0||fansChartData.length > 0||mapData.length > 0" -->
    <div
      class="data-card"
      v-loading="loading"
    >
      <div class="flex-row-center mb16">
        <div class="blue-line" />
        <div class="data-title">{{ $t('dataCentre.fanAnalysis') }}</div>
      </div>
      <el-row :gutter="16">
        <el-col :span="12">
          <div class="fan-analysis">
            <span class="tab-title">{{ $t('dataCentre.genderDistribution') }}</span>
            <pie-echarts
              v-if="sixData.length > 0"
              ref="sexEcharts"
              :piedata="sixData||[]"
              class="mb10"
            />
            <div
              class="no-data flex-column-center f-center"
              v-else
            >
              <svg-icon
                icon-class="data_center_no_data"
                class="no-data-icon"
              />
              <div class="no-data-text">{{ $t('dataCentre.noData') }}</div>
            </div>
          </div>

        </el-col>
        <el-col :span="12">
          <div class="fan-analysis">
            <span class="tab-title">{{ $t('dataCentre.ageDistribution') }}</span>
            <data-echarts
              v-if="ageChartData.length > 0"
              ref="ageEcharts"
              width="100%"
              height="250px"
              chartType="bar"
              :chartData="ageChartData"
              :dateList="ageList"
            />
            <div
              class="no-data flex-column-center f-center"
              v-else
            >
              <svg-icon
                icon-class="data_center_no_data"
                class="no-data-icon"
              />
              <div class="no-data-text">{{ $t('dataCentre.noData') }}</div>
            </div>
          </div>

        </el-col>
        <el-col :span="12">
          <div class="fan-analysis">
            <span class="tab-title">{{ $t('dataCentre.fanGrowth') }}</span>
            <data-echarts
              v-if="fansChartData.length > 0"
              ref="fansEcharts"
              width="100%"
              height="250px"
              chartType="bar"
              :chartData="fansChartData"
              :dateList="dateList"
            />
            <div
              class="no-data  flex-column-center f-center"
              v-else
            >
              <svg-icon
                icon-class="data_center_no_data"
                class="no-data-icon"
              />
              <div class="no-data-text">{{ $t('dataCentre.noData') }}</div>
            </div>
          </div>

        </el-col>
        <el-col :span="12">
          <div class="fan-analysis">
            <span class="tab-title">{{ $t('dataCentre.activeDistribution') }}</span>
            <!-- <pie-echarts
              ref="activeEcharts"
              :piedata="activeData"
              class="mb10"
            /> -->
            <div class="no-data flex-column-center f-center">
              <svg-icon
                icon-class="data_center_no_data"
                class="no-data-icon"
              />
              <div class="no-data-text">{{ $t('dataCentre.noData') }}</div>
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="fan-analysis">
            <span class="tab-title">{{ $t('dataCentre.regionDistribution') }}</span>
            <map-echarts
              ref="mapEcharts"
              v-if="mapData.length > 0"
              :mapData="mapData"
            />
            <div
              class="no-data flex-column-center f-center"
              v-else
            >
              <svg-icon
                icon-class="data_center_no_data"
                class="no-data-icon"
              />
              <div class="no-data-text">{{ $t('dataCentre.noData') }}</div>
            </div>
          </div>

        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import PieEcharts from "./components/PieEcharts.vue";
import DataEcharts from "./components/DataEcharts.vue";
import MapEcharts from "./components/MapEcharts.vue";
import { listAccount, queryAnalytics } from "../../api/account/tiktok";
import Cookies from "js-cookie";
export default {
  name: "DataCentre",
  dicts: ["social_platform"],
  components: {
    PieEcharts,
    DataEcharts,
    MapEcharts,
  },
  data() {
    return {
      loading: false,
      queryParams: {
        platform: null,
        socialId: null,
        date: 7,
        unit: "d", // d:日，m:月，y:年
      },
      accountList: [], //账号列表
      dateFilter: [
        { date: 7, unit: "d", title: this.$t("dataCentre.lastWeek") },
        { date: 30, unit: "d", title: this.$t("dataCentre.lastMonth") },
        { date: 90, unit: "d", title: this.$t("dataCentre.lastThreeMonths") },
        { date: 180, unit: "d", title: this.$t("dataCentre.lastSixMonths") },
        { date: 365, unit: "d", title: this.$t("dataCentre.lastYear") },
      ],
      dataCentreData: {},
      dataList: [],
      currentData: {}, //当前选中的数据
      dateList: [], //横轴日期
      postList: [],
      currentPage: 1, // 当前页
      itemsPerPage: 5, // 每页显示的条数
      gender: {},
      ageChartData: [], //年龄分布-纵轴数据
      ageList: [], //年龄分布-横轴
      fansChartData: [], //粉丝净增量
      // activeData: [
      //   { name: "重度", value: 1048 },
      //   { name: "中度", value: 735 },
      //   { name: "轻度", value: 580 },
      //   { name: "低活", value: 458 },
      // ],
      mapData: [],
    };
  },
  computed: {
    platformFilters() {
      const filters = this.dict.type.social_platform.map((type) => ({
        text: type.label,
        value: type.value,
      }));

      // 在开头添加 "全部" 选项
      filters.unshift({
        text: this.$t("all"),
        value: null, // 或其他合适的值，如空字符串 ""
      });

      return filters;
    },
    accountFilters() {
      if (!this.queryParams.platform) {
        // platform 为 null 或 undefined 时，返回全部数据
        return this.accountList;
      }
      // 根据平台筛选数据
      return this.accountList.filter(
        (account) => account.platform === this.queryParams.platform
      );
    },
    sixData() {
      const sixData = [];
      if (this.gender.genderMale !== undefined) {
        sixData.push({
          name: this.$t("dataCentre.male"),
          value: this.gender.genderMale,
        });
      }

      if (this.gender.genderFemale !== undefined) {
        sixData.push({
          name: this.$t("dataCentre.female"),
          value: this.gender.genderFemale,
        });
      }

      if (this.gender.genderOther !== undefined) {
        sixData.push({
          name: this.$t("dataCentre.unknown"),
          value: this.gender.genderOther,
        });
      }

      return sixData;
    },
    // 计算总页数
    totalPages() {
      return Math.ceil(this.postList.length / this.itemsPerPage);
    },
    // 计算当前页的数据
    currentPageData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.postList.slice(startIndex, endIndex);
    },
    // 计算页面数字
    pageNumbers() {
      let pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  created() {
    const storedData = sessionStorage.getItem("dataCentreData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.dataCentreData = parsedData[0].dataCentreData;
      this.queryParams = parsedData[0].queryParams;
      this.parseData();
      console.log("Retrieved data:", parsedData);
      // 在这里可以使用 parsedData
    } else {
      console.log("No data stored under 'dataCentreData'");
    }
    this.getData();
    this.getAccount();
  },
  methods: {
    // 切换页面
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    getTextByValue(value) {
      const item = this.platformFilters.find((item) => item.value === value);
      return item ? item.text : null; // 如果找到则返回 text，否则返回 null
    },
    getData() {
      this.loading = true;
      queryAnalytics(this.queryParams)
        .then((res) => {
          this.dataCentreData = res.data || {};
          sessionStorage.setItem(
            "dataCentreData",
            JSON.stringify([
              {
                queryParams: this.queryParams,
                dataCentreData: this.dataCentreData,
              },
            ])
          );
          this.parseData();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    parseData() {
      let overview = this.dataCentreData.overview || {};
      this.dataList = [
        {
          title: this.$t("dataCentre.totalFans"),
          num: overview.fans && overview.fans > 0 ? overview.fans : 0,
          select: true,
          echartsData: Array.isArray(this.dataCentreData?.fansTrends)
            ? this.dataCentreData.fansTrends.map((item) => item.fans)
            : [],
          socialProportion: this.dataCentreData?.fansSocialProportion
            ? Object.entries(this.dataCentreData.fansSocialProportion).map(
                ([name, value]) => ({
                  name: this.getTextByValue(name) || name,
                  value: parseFloat(value.toFixed(2)), // 四舍五入保留两位小数
                })
              )
            : [],
        },
        {
          title: this.$t("dataCentre.totalPlays"),
          num: overview.views && overview.views > 0 ? overview.views : 0,
          select: false,
          echartsData: Array.isArray(this.dataCentreData?.viewsTrends)
            ? this.dataCentreData.viewsTrends.map((item) => item.views)
            : [],
          socialProportion: this.dataCentreData?.viewsSocialProportion
            ? Object.entries(this.dataCentreData.viewsSocialProportion).map(
                ([name, value]) => ({
                  name: this.getTextByValue(name) || name,
                  value: parseFloat(value.toFixed(2)), // 四舍五入保留两位小数
                })
              )
            : [],
        },
        {
          title: this.$t("dataCentre.totalLikes"),
          num: overview.likes && overview.likes > 0 ? overview.likes : 0,
          select: false,
          echartsData: Array.isArray(this.dataCentreData?.likesTrends)
            ? this.dataCentreData.likesTrends.map((item) => item.likes)
            : [],
          socialProportion: this.dataCentreData?.likesSocialProportion
            ? Object.entries(this.dataCentreData.likesSocialProportion).map(
                ([name, value]) => ({
                  name: this.getTextByValue(name) || name,
                  value: parseFloat(value.toFixed(2)), // 四舍五入保留两位小数
                })
              )
            : [],
        },
        {
          title: this.$t("dataCentre.totalComments"),
          num:
            overview.comments && overview.comments > 0 ? overview.comments : 0,
          select: false,
          echartsData: Array.isArray(this.dataCentreData?.commentsTrends)
            ? this.dataCentreData.commentsTrends.map((item) => item.comments)
            : [],
          socialProportion: this.dataCentreData?.commentsSocialProportion
            ? Object.entries(this.dataCentreData.commentsSocialProportion).map(
                ([name, value]) => ({
                  name: this.getTextByValue(name) || name,
                  value: parseFloat(value.toFixed(2)), // 四舍五入保留两位小数
                })
              )
            : [],
        },
        {
          title: this.$t("dataCentre.totalShares"),
          num: overview.shares && overview.shares > 0 ? overview.shares : 0,
          select: false,
          echartsData: Array.isArray(this.dataCentreData?.sharesTrends)
            ? this.dataCentreData.sharesTrends.map((item) => item.shares)
            : [],
          socialProportion: this.dataCentreData?.sharesSocialProportion
            ? Object.entries(this.dataCentreData.sharesSocialProportion).map(
                ([name, value]) => ({
                  name: this.getTextByValue(name) || name,
                  value: parseFloat(value.toFixed(2)), // 四舍五入保留两位小数
                })
              )
            : [],
        },
        {
          title: this.$t("dataCentre.totalWorks"),
          num: overview.posts && overview.posts > 0 ? overview.posts : 0,
          select: false,
          echartsData: Array.isArray(this.dataCentreData?.postsTrends)
            ? this.dataCentreData.postsTrends.map((item) => item.posts)
            : [],
          socialProportion: this.dataCentreData?.postsSocialProportion
            ? Object.entries(this.dataCentreData.postsSocialProportion).map(
                ([name, value]) => ({
                  name: this.getTextByValue(name) || name,
                  value: parseFloat(value.toFixed(2)), // 四舍五入保留两位小数
                })
              )
            : [],
        },
      ];
      this.currentData = this.dataList[0];
      console.log("currentData", this.currentData);
      if (this.dataCentreData.postList) {
        this.postList = this.dataCentreData.postList.slice(0, 30);
      } else {
        this.postList = [];
      }
      this.gender = this.dataCentreData.gender || {};
      const trends =
        this.dataCentreData.viewsTrends ||
        this.dataCentreData.likesTrends ||
        this.dataCentreData.commentsTrends ||
        this.dataCentreData.sharesTrends ||
        []; // 可以从其他 trends 中选择，确保它们 time 数据一致
      const dates = trends.map((item) => item.time.split(" ")[0]); // 提取日期部分
      this.dateList = Array.from(new Set(dates)); // 去重（如果必要）
      // 粉丝自增量
      this.fansChartData = Array.isArray(this.dataCentreData?.fansNetIncreases)
        ? this.dataCentreData.fansNetIncreases.map((item) => item.fans)
        : [];
      // 年龄分布
      const ageData = this.dataCentreData.age || {};
      this.ageList =
        Object.keys(ageData).map((key) =>
          key.replace("age", "").replace("_", "-")
        ) || [];
      this.ageChartData = Object.values(ageData) || [];
      // 地域分析
      const countryList = this.dataCentreData.countryList || [];
      if (countryList.length > 0) {
        // 根据语言动态设置 labelField
        const language = Cookies.get("language")
          ? Cookies.get("language")
          : navigator.language === "zh-CN"
          ? "zh_CN"
          : "en_US";

        const labelField = language === "zh_CN" ? "name" : "enName";

        // 转换数据为 mapData
        this.mapData = countryList.map((item) => ({
          name: item.enName,
          showName: item[labelField],
          value: item.count || 0,
        }));
      } else {
        this.mapData = [];
      }
      this.$nextTick().then((rs) => {
        this.$refs.trendEcharts.resize();
        if (
          this.currentData.socialProportion &&
          this.currentData.socialProportion.length > 0
        )
          this.$refs.proportionEcharts.resize();
        if (this.sixData.length > 0) this.$refs.sexEcharts.resize();
        if (this.ageChartData.length > 0) this.$refs.ageEcharts.resize();
        if (this.fansChartData.length > 0) this.$refs.fansEcharts.resize();
        if (this.mapData.length > 0) this.$refs.mapEcharts.resize();
      });
    },
    getAccount() {
      listAccount().then((response) => {
        this.accountList = response.rows;
      });
    },
    platformChange() {
      this.queryParams.socialId = null;
      this.getData();
    },
    clickData(item, index) {
      this.currentData = item;
      this.dataList.forEach((item, index) => {
        item.select = false;
      });
      this.dataList[index].select = true;
      console.log("currentData", this.currentData);
    },

    // 根据索引返回对应的样式类名
    getLabelClass(index) {
      const classes = [
        "data-total-1",
        "data-total-2",
        "data-total-3",
        "data-total-4",
      ];
      return classes[index % classes.length];
    },
    getLabelSelectClass(index) {
      const classes = [
        "data-total-select-1",
        "data-total-select-2",
        "data-total-select-3",
        "data-total-select-4",
      ];
      return classes[index % classes.length];
    },
  },
};
</script>
<style lang="scss" scoped>
.data-container {
  margin: 16px 24px;
}
.data-refresh {
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #0052d9;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-right: 8px;
  cursor: pointer;
}
.data-time {
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-left: 8px;
}
.data-card {
  // background: #ffffff;
  // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  // border-radius: 14px;
  // border: 1px solid #e5e7eb;
  margin-top: 16px;
  padding: 16px;
}
.blue-line {
  width: 4px;
  height: 16px;
  background: #0156ff;
  border-radius: 2px;
  margin-right: 4px;
}
.data-title {
  height: 22px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.data-total-normal {
  background: #daffea;
  border-radius: 10px;
  padding: 16px 26px;
  font-size: 34px;
  cursor: pointer;
}
.data-total-1 {
  background: #daffea;
  color: #21bd66;
}
.data-total-select-1 {
  background: linear-gradient(90deg, #56c4ff 0%, #279ce8 100%);
  box-shadow: 0px 2px 6px 0px rgba(0, 59, 140, 0.55);
  color: #ffffff;
}
.data-total-2 {
  background: #dffdff;
  color: #13cada;
}
.data-total-select-2 {
  background: linear-gradient(90deg, #56c4ff 0%, #279ce8 100%);
  box-shadow: 0px 2px 6px 0px rgba(0, 59, 140, 0.55);
  color: #ffffff;
}
.data-total-3 {
  background: #fff5e4;
  color: #f8a81c;
}
.data-total-select-3 {
  background: linear-gradient(90deg, #56c4ff 0%, #279ce8 100%);
  box-shadow: 0px 2px 6px 0px rgba(0, 59, 140, 0.55);
  color: #ffffff;
}
.data-total-4 {
  background: #fce9ff;
  color: #e138ff;
}
.data-total-select-4 {
  background: linear-gradient(90deg, #56c4ff 0%, #279ce8 100%);
  box-shadow: 0px 2px 6px 0px rgba(0, 59, 140, 0.55);
  color: #ffffff;
}

.data-content {
  background: #f9f9fa;
  border-radius: 8px;
  padding: 8px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
  .data-video-cover {
    width: 42px;
    height: 64px;
    border-radius: 6px;
    object-fit: cover; /* 保持比例，裁剪多余部分 */
    margin-right: 15px;
  }
  .content-topic {
    color: rgba(0, 0, 0, 0.6);
    margin-left: 5px;
  }
  .content-num {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 25px;
    text-align: left;
    font-style: normal;
    margin-left: 6px;
  }
}
.fan-analysis {
  height: 330px;
  background: #fafafb;
  border-radius: 14px;
  padding: 16px;
  margin-bottom: 16px;
}
.tab-title {
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.tab-title-select {
  color: rgba(255, 255, 255, 0.9);
}

.no-border-select .el-input {
  border: none !important; /* 强制去除边框 */
  box-shadow: none !important; /* 移除阴影 */
}
.page-button {
  width: 24px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
  text-align: center;
  font-style: normal;
  cursor: pointer;
  margin-right: 4px;
}
.page-button:hover {
  color: rgba(0, 0, 0, 0.9);
}
.page-button.active {
  color: rgba(0, 0, 0, 0.9);
}
.no-data {
  height: 250px;
}
.no-data-icon {
  width: 84px;
  height: 64px;
  opacity: 0.5;
}
.no-data-text {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #999999;
  line-height: 22px;
  font-style: normal;
  margin-top: 16px;
}
</style>
