var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
      staticStyle: { "overflow-x": "outo", "overflow-y": "hidden" },
    },
    [
      _c(
        "div",
        { staticClass: "video-editor-title flex-row-center f-between mb16" },
        [
          _c(
            "div",
            { staticClass: "flex-row-center" },
            [
              _vm._v(_vm._s(_vm.$t("workbench.videoProduction")) + " "),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "left-start",
                    width: "518",
                    trigger: "click",
                  },
                },
                [
                  _c("div", { staticClass: "popover-title" }, [
                    _vm._v(_vm._s(_vm.$t("guidance.vidEditIntro"))),
                  ]),
                  _c("div", { staticClass: "popover-content" }, [
                    _vm._v(
                      _vm._s(_vm.$t("guidance.vidEditInstructions")) + " "
                    ),
                  ]),
                  _c("video", {
                    ref: "videoPlayer",
                    staticClass: "video-player",
                    attrs: { controls: "", src: _vm.videoSrc },
                  }),
                  _c(
                    "div",
                    { attrs: { slot: "reference" }, slot: "reference" },
                    [
                      _c(
                        "el-tooltip",
                        { attrs: { content: _vm.$t("guidance.guide") } },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-zhushi ml8 pointer",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "flex-row-center pointer back-list",
              on: { click: _vm.goBack },
            },
            [
              _c("i", { staticClass: "iconfont icon-fanhui mr8 " }),
              _vm._v(" " + _vm._s(_vm.$t("backList")) + " "),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "flex-row" }, [
        _c(
          "div",
          { staticClass: "ve-step editor-scroll f-shrink" },
          [
            _c("StepProgressBar", {
              attrs: { steps: _vm.steps, activeStep: _vm.currentStep },
              on: { "update:activeStep": _vm.updateStep },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "f-grow flex-column " }, [
          _c("div", { staticClass: "f-grow middle-right-container" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentStep === 0,
                    expression: "currentStep===0",
                  },
                ],
                staticClass: "flex-row",
              },
              [
                _c(
                  "div",
                  { staticClass: "f-grow editor-scroll base-info-container" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "videoTitle basic-info-title flex-row-center",
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "title-bg-svg",
                          attrs: { "icon-class": "text_bg" },
                        }),
                        _c("span", { staticClass: "ml16" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("videoEditor.info")) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "set-des",
                        staticStyle: { margin: "8px 16px" },
                      },
                      [_vm._v(_vm._s(_vm.$t("videoSetting.videoNameLocation")))]
                    ),
                    _c(
                      "el-form",
                      {
                        ref: "totalInfo",
                        attrs: {
                          model: _vm.totalInfo,
                          rules: _vm.rules,
                          "label-width": "120px",
                          "label-position": "top",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("videoEditor.videoName"),
                              prop: "taskName",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "340px" },
                              attrs: {
                                maxlength: "50",
                                "show-word-limit": "",
                                placeholder: _vm.$t("tip.input"),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.updatedMixing(_vm.totalInfo)
                                },
                              },
                              model: {
                                value: _vm.totalInfo.taskName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.totalInfo, "taskName", $$v)
                                },
                                expression: "totalInfo.taskName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("videoEditor.videoRatio"),
                              prop: "aspectRatio",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.totalInfo.aspectRatio,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.totalInfo, "aspectRatio", $$v)
                                  },
                                  expression: "totalInfo.aspectRatio",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  { attrs: { label: "9:16", border: "" } },
                                  [_vm._v("9:16")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("aiSet.location"),
                              prop: "productTypeId",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "340px" },
                                attrs: {
                                  placeholder: _vm.$t("file.tipSelectFolder"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updatedMixing(_vm.totalInfo)
                                  },
                                },
                                model: {
                                  value: _vm.totalInfo.productTypeId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.totalInfo,
                                      "productTypeId",
                                      $$v
                                    )
                                  },
                                  expression: "totalInfo.productTypeId",
                                },
                              },
                              _vm._l(_vm.productTypelist, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "editor-scroll mix-right f-shrink" },
                  [
                    _c("MixingSettings", {
                      ref: "mixingSettings",
                      attrs: { value: _vm.totalInfo },
                      on: { "item-updated": _vm.updatedMixing },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "editor-scroll f-shrink",
                    staticStyle: { width: "293px", padding: "16px" },
                  },
                  [
                    _c("div", { staticClass: "videoTitle" }, [
                      _vm._v(_vm._s(_vm.$t("videoEditor.effectPreview"))),
                    ]),
                    _c("div", { staticClass: "set-des" }, [
                      _vm._v(_vm._s(_vm.$t("videoEditor.viewConfigEffect"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "preview", style: _vm.previewStyle },
                      [
                        _vm.previewImg
                          ? _c("img", {
                              staticClass: "preview-img",
                              attrs: { src: _vm.previewImg },
                            })
                          : _vm._e(),
                        _vm.previewVideo && !_vm.previewImg
                          ? _c("video", {
                              staticClass: "preview-img",
                              attrs: { src: _vm.previewVideo },
                            })
                          : _vm._e(),
                        _vm.totalInfo.logoTrackClipsBo &&
                        _vm.totalInfo.logoTrackClipsBo.mediaUrl
                          ? _c("img", {
                              staticClass: "logo-image",
                              style: _vm.logoStyle,
                              attrs: {
                                src: _vm.totalInfo.logoTrackClipsBo.mediaUrl,
                              },
                            })
                          : _vm._e(),
                        _c("p", { style: _vm.mixPreviewStyle }, [
                          _vm._v(_vm._s()),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentStep > 0,
                    expression: "currentStep > 0",
                  },
                ],
              },
              _vm._l(_vm.totalInfo.videoSceneBos, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _vm.currentStep - 1 == index
                      ? _c("task-item", {
                          ref: "taskItems",
                          refInFor: true,
                          attrs: {
                            value: item,
                            effectColorStyleList: _vm.effectColorStyleList,
                            setupSync: _vm.totalInfo.setupSync,
                          },
                          on: {
                            "update:setupSync": function ($event) {
                              return _vm.$set(
                                _vm.totalInfo,
                                "setupSync",
                                $event
                              )
                            },
                            "update:setup-sync": function ($event) {
                              return _vm.$set(
                                _vm.totalInfo,
                                "setupSync",
                                $event
                              )
                            },
                            "item-updated": function ($event) {
                              return _vm.handleItemUpdated(index, $event)
                            },
                            remove: function ($event) {
                              return _vm.remove(index)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _vm.currentStep === 0
            ? _c(
                "div",
                { staticClass: "flex-center mt10" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "gradient-button",
                      on: { click: _vm.addScene },
                    },
                    [_vm._v(_vm._s(_vm.$t("videoEditor.addScene")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "button-white",
                      attrs: { loading: _vm.buttonLoading1 },
                      on: { click: _vm.saveDraft },
                    },
                    [
                      _c("div", { staticClass: "flex-center" }, [
                        _c("i", {
                          staticClass: "iconfont icon-sousuotiaojianbaocun mr5",
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("videoEditor.draft")) + " "),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "flex-center mt10" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "gradient-button",
                      on: { click: _vm.chooseMaterialbyScene },
                    },
                    [_vm._v(_vm._s(_vm.$t("videoEditor.importByScene")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "gradient-button",
                      on: { click: _vm.addScene },
                    },
                    [_vm._v(_vm._s(_vm.$t("videoEditor.addScene")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "delete-button",
                      on: {
                        click: function ($event) {
                          return _vm.remove(_vm.currentStep - 1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("videoEditor.deleteScene")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "gradient-button",
                      attrs: { loading: _vm.buttonLoading3 },
                      on: {
                        click: function ($event) {
                          return _vm.createVideo(1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("videoEditor.generateVideo")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "button-white",
                      attrs: { loading: _vm.buttonLoading1 },
                      on: { click: _vm.saveDraft },
                    },
                    [
                      _c("div", { staticClass: "flex-center" }, [
                        _c("i", {
                          staticClass: "iconfont icon-sousuotiaojianbaocun mr5",
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("videoEditor.draft")) + " "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
        ]),
      ]),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("videoEditor.previewResult"),
            size: "65%",
            visible: _vm.drawer,
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("VideoPreview", {
            ref: "videoPreview",
            on: { createTaskSuccess: _vm.createTaskSuccess },
          }),
        ],
        1
      ),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.materialDrawer },
        on: {
          "update:visible": function ($event) {
            _vm.materialDrawer = $event
          },
          selectionConfirmed: _vm.selectionConfirmed,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }