<template>
  <div
    ref="charts"
    :class="className"
    :style="{ height: height, width: width }"
  />
</template>

<script>
import * as echarts from "echarts";
import resize from "./resize";

require("echarts/theme/macarons"); // echarts theme

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "350px",
    },
    title: {
      type: String,
      default: "",
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    chartType: {
      type: String,
      default: "line", // 可选值为 "line" 或 "bar"
      validator(value) {
        return ["line", "bar"].includes(value); // 验证类型合法性
      },
    },
    chartData: {
      type: Array,
      required: true, // 图表数据
    },
    dateList: {
      type: Array,
      required: true, // X轴分类数据
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.initChart();
      },
    },
    chartType() {
      this.initChart(); // 当图表类型变化时重新初始化
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  },
  methods: {
    resize() {
      this.$nextTick(() => {
        echarts.getInstanceByDom(this.$refs.charts).resize();
      });
    },
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.setOptions(this.chartData);
    },
    setOptions(data) {
      this.chart.setOption({
        title: {
          text: this.title,
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow", // 根据图表类型自动适配
          },
        },
        grid: {
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: this.chartType === "bar", // 柱状图需要有边界间隙
          data: this.dateList,
          axisLine: {
            lineStyle: {
              color: "rgba(129,127,155,0.7)", // 横轴颜色
              type: "dashed", // 虚线
            },
          },
          axisTick: {
            show: false, // 隐藏刻度线
          },
          axisLabel: {
            color: "rgba(129,127,155,0.7)", // 横轴标签颜色
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false, // 隐藏竖轴
          },
          axisTick: {
            show: false, // 隐藏刻度线
          },
          axisLabel: {
            color: "rgba(129,127,155,0.7)", // 纵轴标签颜色
          },
          splitLine: {
            lineStyle: {
              color: "rgba(129,127,155,0.3)", // 网格线颜色
              type: "dashed", // 虚线
            },
          },
        },
        series: [
          {
            name: this.$t("dataCentre.dataTrend"),
            type: this.chartType, // 根据传参切换图表类型
            smooth: this.chartType === "line", // 仅对折线图生效
            lineStyle:
              this.chartType === "line" ? { color: "#0156FF" } : undefined, // 折线图线条样式
            areaStyle:
              this.chartType === "line"
                ? {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        { offset: 0, color: "rgba(100,93,246,0.15)" }, // 顶部颜色
                        { offset: 1, color: "rgba(89, 120, 236, 0)" }, // 底部透明
                      ],
                      global: false, // 缺省为 false
                    },
                  }
                : undefined, // 折线图区域样式
            data: data.map((value) => ({
              value,
              itemStyle: {
                color: value >= 0 ? "#0156FF" : "#FEA600", // 根据值动态设置颜色
              },
            })),
            barWidth: this.chartType === "bar" ? "30%" : undefined, // 柱状图宽度
          },
        ],
      });
    },
  },
};
</script>
