<template>
  <div class="video-container">
    <!-- <div class="title-header">
      <div class="title-header-text">{{ $t('workbench.videoProduction') }}</div> -->
    <div
      class="flex-row-center f-between mb16"
      style="height: 48px;"
    >
      <div class="title-header-text">
        {{ $t('videoManager.videoList') }}
      </div>
      <div
        v-if="quota.videoLength"
        class="flex-row-center"
      >
        <span class="mr5">{{$t('quota.quota')}}：</span>
        <Slider
          :value="formatQuota(quota.useVideoLength) || 0"
          :maxValue="formatQuota(quota.videoLength) || 1000"
        />
        <div class="ml5">
          {{ quota.useVideoLength || 0}}/{{ quota.videoLength || '1000'+ $t('quota.minute')}}
        </div>
      </div>
    </div>
    <!-- </div> -->
    <div class="video-content">
      <div class="flex-row-center f-between w-100">
        <div class="f-shrink">
          <el-button
            class="gradient-button"
            icon="el-icon-plus"
            @click="createVideo"
          >{{$t('videoManager.newTask')}}</el-button>
        </div>
        <div class="flex-row-center">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('refresh')"
            placement="top"
          >
            <i
              class="iconfont icon-a-danyuanpeizhi6"
              @click="refresh"
            ></i>
          </el-tooltip>
          <el-radio-group
            v-model="styleType"
            class="custom-radio-group"
          >
            <el-radio
              label="grid"
              :border="styleType === 'grid'"
            >
              <template v-slot:default>
                <i
                  class="iconfont font20 icon-grid"
                  :class="styleType === 'grid' ? 'gradient-purple' : 'unbind'"
                ></i>
              </template>
            </el-radio>
            <el-radio
              label="list"
              :border="styleType === 'list'"
            >
              <template v-slot:default>
                <i
                  class="iconfont font20 icon-list"
                  :class="styleType === 'list' ? 'gradient-purple' : 'unbind'"
                ></i>
              </template>
            </el-radio>
          </el-radio-group>
          <div style="width: 400px;margin:0 5px;">
            <el-input
              class="account-input"
              v-model="queryParams.searchValue"
              :placeholder="$t('videoManager.tipName')"
              @keyup.enter.native="handleQuery"
              clearable
            >
              <i
                slot="prefix"
                class="el-input__icon iconfont icon-sousuotubiao"
                @click="handleQuery"
              ></i>
            </el-input>
          </div>

        </div>
      </div>
      <!-- <el-row class="flex-row-center">
        <el-form class="f-grow" :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch">
          <el-form-item label="" prop="status">
            <el-select v-model="queryParams.status" :placeholder="$t('videoManager.status')" @change="handleQuery" clearable style="width: 240px">
              <el-option v-for="dict in dict.type.video_status" :key="dict.value" :label="dict.label"
                :value="dict.value" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">{{$t('search')}}</el-button>
            <el-tooltip class="item" effect="dark" :content="$t('refresh')" placement="top">
              <el-button size="mini" circle icon="el-icon-refresh" @click="refresh" />
            </el-tooltip>
          </el-form-item>
        </el-form>
      </el-row> -->
      <div
        class="flex-row f-wrap mt10"
        style="height: calc(100vh - 320px); overflow-y: auto;"
        v-loading="loading"
        v-show="styleType === 'grid'"
      >
        <el-empty
          v-if="videos.length === 0"
          class="w-100"
          :image-size="180"
        ></el-empty>
        <video-grid-item
          class="video-parent"
          v-for="(video, index) in videos"
          :key="index"
          :video="video"
          @delete-video="deleteVideo"
          @edit-video="editVideo"
        />
      </div>
      <!-- <el-card> -->
      <el-table
        v-show="styleType === 'list'"
        v-loading="loading"
        header-row-class-name="custom_table_header"
        height="calc(100vh - 320px)"
        style="width: 100%;margin-top: 10px"
        :data="videos"
        :row-style="setRowStyle"
        @filter-change="filterChange"
        @cell-mouse-enter="cellMouseEnter"
        @cell-mouse-leave="cellMouseLeave"
      >
        <el-empty
          :image-size="180"
          slot="empty"
        ></el-empty>
        <el-table-column
          :label="$t('editVideo.video')"
          prop="url"
          min-width="122"
        >
          <template slot-scope="scope">
            <div class="flex-row-center">
              <div
                v-if="scope.row.status == 0"
                class="video-cover video-bg flex-center"
              >
                <i class="iconfont icon-shipincaogao"></i>
              </div>
              <div
                v-else-if="scope.row.status == 1"
                class="video-cover video-bg flex-center"
              >
                <i class="iconfont icon-shipinshengchengzhong"></i>
              </div>
              <div
                v-else-if="scope.row.status == 2"
                class="video-cover video-bg flex-center"
              >
                <i class="iconfont icon-shipinshengchengshibai"></i>
              </div>
              <div v-else>
                <div class="cellVideo">
                  <video
                    class="video-cover"
                    :src="scope.row.url"
                  ></video>
                  <video-button
                    @closeViewVideo="closeViewVideo"
                    @viewVideo="viewVideo"
                    :url="scope.row.url"
                    :tip="$t('issueManager.playVideo')"
                  >
                    <template slot="customButton">
                      <div class="cricle">
                        <i class="el-icon-caret-right"></i>
                      </div>
                    </template>
                  </video-button>
                </div>

              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="taskName"
          :label="$t('videoManager.name')"
          align="center"
          min-width="100"
        >
          <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.taskName"
              placement="top"
            >
              <span class="single-line-ellipsis pointer">{{ scope.row.taskName }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="videoTotalLength"
          :label="$t('videoManager.length')"
          align="center"
          min-width="90"
        >
          <template slot-scope="scope">
            {{ secondsFormatTime(scope.row.videoTotalLength) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('videoManager.status')"
          align="center"
          min-width="270"
        >
          <template slot-scope="scope">
            <div
              :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'"
              class="video-status"
              :class="'video-status-' + scope.row.status"
            >
              <span v-if="scope.row.status == 0">{{$t('videoManager.statusDraft')}}</span>
              <div
                v-if="scope.row.status == 1"
                class="flex-column"
              >
                <span>{{ $t('status.generating') }}...</span>
                <span class="video-status-des">{{ $t('status.generatingTip') }}</span>
              </div>
              <div
                v-if="scope.row.status == 2"
                class="hidden flex-column"
              >
                {{ $t('issueManager.failed') }}
                <!-- <el-tooltip
                  v-if="scope.row.errorMessage && scope.row.errorMessage.length > 5"
                  :content="scope.row.errorMessage"
                  placement="top"
                >
                  <span class="single-line-ellipsis pointer video-status-des">{{ scope.row.errorMessage }}</span>
                </el-tooltip> -->
                <span
                  v-if="scope.row.errorMessage"
                  class="video-status-des"
                >{{ scope.row.errorMessage }}</span>
              </div>
              <div v-if="scope.row.status == 3"> {{ $t('model.generated') }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          column-key="taskType"
          :filters="issueTypeFilters"
          :filter-multiple="false"
          prop="taskType"
          :label="$t('videoManager.type')"
          align="center"
          min-width="80"
        >
          <template slot-scope="scope">
            <dict-tag
              :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'"
              :options="dict.type.issue_type"
              :value="scope.row.taskType"
            />
            <div
              v-show="scope.row.hover"
              class="flex-row-center operate-hover"
            >
              <div
                v-if="scope.row.status != 0 && scope.row.taskType == 1 && scope.row.parentId"
                class="flex-row-center list-button"
                @click="makeAgain(scope.row)"
              >
                <i class="iconfont icon-fuzhi"></i>
                {{ $t('videoManager.makeAgain') }}
              </div>
              <div
                v-if="scope.row.status == 3 &&scope.row.publishStatus == 0"
                class="flex-row-center list-button"
                @click="publish(scope.row)"
              >
                <i class="iconfont icon-xingzhuangjiehe"></i>
                {{ $t('videoManager.publish') }}
              </div>
              <div
                v-if="scope.row.status == '0'"
                class="flex-row-center list-button"
                @click="editVideo(scope.row)"
              >
                <i class="iconfont icon-edit"></i>
                {{ $t('edit') }}
              </div>

              <div
                v-if="scope.row.taskType==2"
                class="flex-row-center list-button"
                @click="aiPage(scope.row)"
              >
                <i class="iconfont icon-a-xialajiantoubeifen3"></i>
                {{ $t('videoManager.aiPage') }}
              </div>

              <div
                v-if="scope.row.status == '3'"
                class="flex-row-center list-button"
                @click="downloadFile(scope.row.url)"
              >
                <i class="iconfont icon-a-dagoubeifen21"></i>
                {{ $t('file.download') }}
              </div>
              <div
                class="flex-row-center list-button"
                @click="deleteVideo(scope.row)"
              >
                <i class="iconfont icon-delete"></i>
                {{ $t('remove') }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          :label="$t('time.createTime')"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <div :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'">{{ scope.row.createTime }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="jobFinishedTime"
          :label="$t('time.generatedTime')"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <div :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'">{{ scope.row.jobFinishedTime }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="publishStatus"
          :label="$t('videoManager.publishStatus')"
          align="center"
          min-width="270"
        >
          <template slot-scope="scope">
            <div
              class="publish-status"
              :class="'publish-status-' + scope.row.publishStatus"
              :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'"
            >
              <div
                v-if="scope.row.publishStatus == 0"
                class="flex-center"
              > <i class="iconfont icon-dacha3"></i>
                {{ $t('issueManager.unpublished') }}
              </div>
              <div
                v-if="scope.row.publishStatus == 1"
                class="flex-center"
              >
                <i class="iconfont icon-dacha1"></i>
                {{ $t('issueManager.publishing') }}
              </div>
              <div
                v-if="scope.row.publishStatus == 3"
                class="hidden flex-column"
              >
                <div class="flex-center">
                  <i class="iconfont icon-a-dacha2"></i>
                  {{ $t('issueManager.failed') }}
                </div>
                <!-- <el-tooltip
                  v-if="scope.row.publishError && scope.row.publishError.length > 5"
                  :content="scope.row.publishError"
                  placement="top"
                >
                  <span class="single-line-ellipsis pointer publish-status-des">{{ scope.row.publishError }}</span>
                </el-tooltip> -->
                <span
                  v-if="scope.row.publishError"
                  class="publish-status-des"
                >{{ scope.row.publishError }}</span>
              </div>
              <div
                v-if="scope.row.publishStatus == 2"
                class="flex-center"
              >
                <i class="iconfont icon-a-dacha3"></i>
                {{ $t('issueManager.published') }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('videoManager.publishAccount')"
          align="center"
          min-width="110px"
        >
          <template slot-scope="scope">
            <div
              class="flex-column"
              :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'"
            >
              <div v-if="scope.row.publishList && scope.row.publishList.length > 0">
                <div
                  class="flex-row-center"
                  v-for="publishItem in scope.row.publishList"
                  :key="publishItem.id"
                >
                  <el-avatar
                    :size="20"
                    :src="publishItem.userImage"
                    class="f-shrink"
                  ></el-avatar>
                  <span class="ml5">{{ publishItem.displayName }}</span>
                </div>
              </div>

            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="publishTime"
          :label="$t('videoManager.publishTime')"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <div :style="scope.row.hover ? 'opacity: 0' : 'opacity: 1'">{{ scope.row.publishTime }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :label="$t('operate')"
          width="300"
          align="center"
        >
          <template slot-scope="scope">
            <el-tooltip
              :content="$t('videoManager.publish')"
              v-if="scope.row.status == 3 &&scope.row.publishStatus == 0"
            >
              <el-button
                icon="iconfont icon-xingzhuangjiehe"
                circle
                @click="publish(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              :content="$t('edit')"
              v-if="scope.row.status == '0'"
            >
              <el-button
                icon="iconfont icon-edit"
                circle
                @click="editVideo(scope.row)"
              ></el-button>
            </el-tooltip>
            <span
              v-if="scope.row.status == '3'"
              style="display: inline-block;"
              class="ml5 mr5"
            >
              <video-button
                :url="scope.row.url"
                :tip="$t('videoManager.playVideo')"
              ></video-button>
            </span>
            <el-tooltip
              :content="$t('videoManager.aiPage')"
              v-if="scope.row.taskType==2"
            >
              <el-button
                icon="el-icon-help"
                circle
                @click="aiPage(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              :content="$t('file.download')"
              v-if="scope.row.status == '3'"
            >
              <el-button
                icon="iconfont icon-daoru"
                circle
                @click="downloadFile(scope.row.url)"
              ></el-button>
            </el-tooltip>
            <el-tooltip :content="$t('remove')">
              <el-button
                icon="iconfont icon-delete"
                circle
                @click="deleteVideo(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
      <!-- </el-card> -->
    </div>
  </div>
</template>

<script>
import { delVideoTask, listVideoTask } from "../../api/ai/videoTask";
import { getQuota } from "../../api/system/merchant";
import VideoButton from "../../components/VideoButton/VideoButton.vue";
import Slider from "../account/slider.vue";
import VideoGridItem from "../../components/VideoManager/VideoGridItem.vue";

export default {
  // name:"VideoManager",//为了不缓存 先注掉
  dicts: ["video_status", "issue_type"],
  components: {
    VideoButton,
    Slider,
    VideoGridItem,
  },
  data() {
    return {
      loading: false,
      showSearch: true,
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        status: "",
        taskTypes: "1,2,4",
      },
      // 总条数
      total: 0,
      videos: [],
      quota: {}, //配额
      styleType: "list", //grid or list
      timer: null, //定时器——生成中的状态更新
      isViewVideo: false,
    };
  },
  computed: {
    issueTypeFilters() {
      return this.dict.type.issue_type
        .map((type) => ({
          text: type.label,
          value: type.value,
        }))
        .filter((item) => item.value != "3");
    },
  },
  created() {
    this.getQuota();
    this.getList();
  },
  beforeDestroy() {
    // 页面销毁前清除定时器
    this.clearTimer();
  },
  methods: {
    // 设置固定行高
    setRowStyle() {
      return { height: "80px" };
    },
    closeViewVideo() {
      this.isViewVideo = false;
    },
    viewVideo(row) {
      this.isViewVideo = true;
    },
    cellMouseLeave(row, column, cell, event) {
      if (this.isViewVideo) return;
      row.hover = false;
    },
    cellMouseEnter(row, column, cell, event) {
      row.hover = true;
      // console.log("cellMouseEnter", row);
    },
    // 筛选
    filterChange(filter) {
      if (filter["taskType"]) {
        // 修改传给后端接口的参数，并重新调用接口
        this.queryParams.taskTypes = filter.taskType.join(",");
        this.handleQuery();
      }
    },
    //刷新
    refresh() {
      this.getQuota();
      this.getList();
    },
    //搜索
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    //获取配额
    getQuota() {
      getQuota().then((response) => {
        this.quota = response.data;
      });
    },
    getList() {
      this.loading = true;
      listVideoTask(this.queryParams)
        .then((response) => {
          this.loading = false;
          this.videos = response.rows;
          this.total = response.total;
          this.videos.forEach((item) => {
            this.$set(item, "hover", false); // 让 Vue 监听新属性
          });
          // 检查是否存在 生成中 的项
          if (this.videos.some((item) => item.status == 1)) {
            // 如果定时器不存在，启动定时器
            if (!this.timer) {
              this.timer = setInterval(() => {
                this.getList();
              }, 30000); // 每30秒调用一次
            }
          } else {
            this.clearTimer();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    //AI详情
    aiPage(row) {
      this.$router.push({
        path: `/videoManager/make_video/${row.aiVideoId}`,
      });
    },
    //重置查询
    resetQuery() {
      this.queryParams.status = "";
      this.queryParams.taskTypes = "1,2,4";
      this.getList();
    },
    // 创建新视频
    createVideo() {
      if (
        this.isExceedQuota(this.quota.useVideoLength, this.quota.videoLength)
      ) {
        this.$modal.msgError(this.$t("material.exceedQuota"));
        return;
      }
      this.$router.push({ path: "/videoEditor" });
      // const url = '/videoEditor';
      // window.open(url, '_blank');
    },

    //去发布
    publish(row) {
      this.$router.push({
        path: `/issueVideo/${row.productId}`,
        query: { isProductId: true, url: row.url },
      });
    },
    // 再次制作
    makeAgain(row) {
      this.$router.push({
        path: `/videoEditor/${row.parentId}`,
        query: { type: "add" },
      });
    },
    // 编辑视频
    editVideo(row) {
      this.$router.push({
        path: `/videoEditor/${row.id}`,
        query: { type: "edit" },
      });
    },
    //删除草稿
    deleteVideo(row) {
      this.$confirm(this.$t("videoManager.tipDelete"), this.$t("tip.tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        // 处理删除逻辑
        delVideoTask(row.id).then((response) => {
          this.$modal.msgSuccess(this.$t("tip.remove"));
          this.getList();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  margin: 15px;
}
.video-content {
  padding-bottom: 15px;
  /* background: #ffffff; */
  /* border: 1px solid rgba(229, 231, 235, 1); */
  /* border-radius: 14px; */
}

.video-cover {
  width: 102px;
  height: 56px;
  border-radius: 12px;
  object-fit: cover; /* 保持比例，裁剪多余部分 */
}
.cellVideo {
  width: 102px;
  height: 56px;
  position: relative;
  overflow: hidden;
  .cricle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    .el-icon-caret-right {
      color: #fff;
      font-size: 14px;
    }
  }
}
.icon-shipincaogao,
.icon-shipinshengchengzhong,
.icon-shipinshengchengshibai {
  font-size: 24px;
  color: #b4b5b6;
}
.video-bg {
  background-color: #f3f4f6;
}
.custom-radio-group {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}
.custom-radio-group ::v-deep.el-radio {
  margin-right: 5px;
}
.custom-radio-group ::v-deep.el-radio.is-bordered.is-checked {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}
.custom-radio-group ::v-deep.el-radio__input {
  display: none; /* 隐藏原点 */
}
// /* 隐藏 Element UI 默认的筛选图标 */
// ::v-deep .el-table__column-filter-trigger {
//   display: none;
// }

/* 选中带有 filters 属性的表头，并添加自定义 iconfont 图标 */
// .el-table th[column-key="taskType"]
::v-deep .el-table__column-filter-trigger i {
  font-family: "iconfont" !important;
  content: "\e631";
  font-style: normal !important;
}
/* 让新图标显示出来 */
::v-deep .el-table__column-filter-trigger i::before {
  font-family: "iconfont" !important;
  content: "\e631";
  font-size: 16px !important;
}
.video-status {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
}
.video-status-des {
  opacity: 0.8;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
}
.video-status-0 {
  color: rgba(0, 0, 0, 0.4);
}
.video-status-1 {
  color: #ed7b2f;
}
.video-status-2 {
  color: #e34d59;
}
.video-status-3 {
  color: #0156ff;
}
.publish-status {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 22px;
  font-style: normal;
  i {
    margin-right: 8px;
  }
}
.publish-status-des {
  opacity: 0.8;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
}
.publish-status-0 {
  color: rgba(0, 0, 0, 0.4);
}
.publish-status-1 {
  color: #ed7b2f;
}
.publish-status-2 {
  color: #00a870;
}
.publish-status-3 {
  color: #e34d59;
}
.video-parent {
  width: calc(16.6% - 29px);
}
</style>
