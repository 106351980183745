var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-settings" },
    [
      _c(
        "el-form",
        {
          ref: "settingsForm",
          attrs: {
            model: _vm.settings,
            rules: _vm.rules,
            "label-width": "120px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Logo" } },
            [
              _c("imageUpload", {
                attrs: { limit: 5 },
                model: {
                  value: _vm.fileList,
                  callback: function ($$v) {
                    _vm.fileList = $$v
                  },
                  expression: "fileList",
                },
              }),
              _c(
                "el-dialog",
                {
                  attrs: { visible: _vm.dialogVisible },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "背景颜色", prop: "bgColor" } },
            [
              _c(
                "div",
                {
                  staticClass: "color-picker-container",
                  on: { click: _vm.showColorPicker },
                },
                [
                  _c("el-color-picker", {
                    ref: "colorPicker",
                    attrs: { predefine: _vm.predefinedColors },
                    model: {
                      value: _vm.settings.bgColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "bgColor", $$v)
                      },
                      expression: "settings.bgColor",
                    },
                  }),
                  _c("span", { staticClass: "ml-10" }, [
                    _vm._v(_vm._s(_vm.settings.bgColor || "#ffffff")),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "背景音乐音量", prop: "bgMusicVolume" } },
            [
              _c("el-slider", {
                staticStyle: { width: "50%" },
                attrs: { max: 100, min: 0, "show-input": "" },
                model: {
                  value: _vm.settings.bgMusicVolume,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings, "bgMusicVolume", $$v)
                  },
                  expression: "settings.bgMusicVolume",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "视频音色", prop: "voiceType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.settings.voiceType,
                    callback: function ($$v) {
                      _vm.$set(_vm.settings, "voiceType", $$v)
                    },
                    expression: "settings.voiceType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "男声" } }, [
                    _vm._v("男声"),
                  ]),
                  _c("el-radio", { attrs: { label: "女声" } }, [
                    _vm._v("女声"),
                  ]),
                  _c("el-radio", { attrs: { label: "童声" } }, [
                    _vm._v("童声"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "存储位置" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择存储位置" },
                  model: {
                    value: _vm.settings.storageLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.settings, "storageLocation", $$v)
                    },
                    expression: "settings.storageLocation",
                  },
                },
                _vm._l(_vm.storageOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("保存设置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }