<template>
  <div
    ref="charts"
    :class="className"
    :style="{height:height,width:width,minWidth: '250px'} "
  />
</template>

<script>
import * as echarts from "echarts";
import resize from "./resize";

require("echarts/theme/macarons"); // echarts theme

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "250px",
    },
    piedata: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
      currentData: [],
    };
  },
  watch: {
    piedata: {
      deep: true,
      handler(val) {
        this.currentData = val;
        this.initChart();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    resize() {
      this.$nextTick(() => {
        echarts.getInstanceByDom(this.$refs.charts).resize();
      });
    },
    init(data) {
      this.piedata = data;
      this.initChart();
    },
    initChart() {
      // 如果piedata中包含total项，重新计算它们的值
      if (this.piedata.some((item) => item.name === "total")) {
        // 获取total的值（它应该是1）
        const totalItem = this.piedata.find((item) => item.name === "total");
        const totalValue = totalItem.value; // total 的原始值
        const otherItems = this.piedata.filter((item) => item.name !== "total");

        // 计算其他项的总和
        const otherTotal = otherItems.reduce(
          (sum, item) => sum + item.value,
          0
        );

        // 更新其他项的值为百分比
        const updatedItems = otherItems
          .map((item) => ({
            name: item.name,
            value: (item.value / totalValue) * 100,
          }))
          .filter((item) => item.value > 0);

        // 计算剩余值，并添加为total项
        const remainingValue =
          100 - updatedItems.reduce((sum, item) => sum + item.value, 0);
        if (remainingValue > 0) {
          updatedItems.push({
            name: "other",
            value: remainingValue,
          });
        }
        this.currentData = updatedItems; // 更新 piedata
      }
      // 设置颜色
      const colors = [
        "#2A78EF",
        "#F4A465",
        "#50CDA3",
        "#E3D43D",
        "#FF7800",
        "#39EAE8",
        "#FF0089",
        "#C65CDD",
      ];

      // 设置每个项的颜色
      const data = this.currentData.map((item, index) => ({
        ...item,
        itemStyle: {
          color:
            item.name === "other" ? "#E1E5F3" : colors[index % colors.length], // 给total设置特定颜色，其它项按顺序使用已定义颜色
        },
      }));
      this.chart = echarts.init(this.$el, "macarons");
      this.chart.setOption({
        tooltip: {
          trigger: "item",
          // formatter: '{a} <br/>{b} : {c} ({d}%)'
          formatter: "{b} : {c} ({d}%)",
        },
        legend: {
          left: "center",
          top: "0",
          data: this.currentData.map((e) => e.name),
        },
        series: [
          {
            name: "",
            type: "pie",
            minAngle: 2, // 最小的扇区角度（0~360），用于防止某个值过小导致扇区太小影响交互
            avoidLabelOverlap: true, // 是否启用防止标签重叠策略
            stillShowZeroSum: true,
            top: 20,
            label: {
              fontSize: 12,
              fontWeight: "normal",
              position: "outside", // 也可以尝试 'outside、inside' 等值
              formatter: "{b}",
            },
            // roseType: 'radius',
            radius: "70%",
            center: ["50%", "50%"],
            data: data,
            animationEasing: "cubicInOut",
            animationDuration: 2600,
          },
        ],
      });
    },
  },
};
</script>
