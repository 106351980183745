<template>
  <div
    ref="charts"
    :style="{ height: height, width: width }"
  />
</template>

  <script>
import * as echarts from "echarts";
import "echarts/map/js/world"; // 世界地图数据

export default {
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "250px",
    },
    mapData: {
      type: Array,
      default: () => [
        { name: "Japan", showName: "日本", value: 80 },
        { name: "China", showName: "中国", value: 100 },
        { name: "United States", showName: "美国", value: 90 },
      ],
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChart();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  },
  methods: {
    resize() {
      this.$nextTick(() => {
        echarts.getInstanceByDom(this.$refs.charts).resize();
      });
    },
    initChart() {
      this.chart = echarts.init(this.$refs.charts);
      this.setOptions();
    },
    setOptions() {
      const colors = [
        "#2A78EF",
        "#F4A465",
        "#50CDA3",
        "#E3D43D",
        "#FF7800",
        "#39EAE8",
        "#FF0089",
        "#C65CDD",
      ];
      // 排序 mapData 按照 value 从大到小
      const sortedMapData = [...this.mapData].sort((a, b) => b.value - a.value);

      const countries = sortedMapData.map((item) => item.showName);
      const values = sortedMapData.map((item) => item.value);
      // const countries = this.mapData.map((item) => item.name);
      // const values = this.mapData.map((item) => item.value);
      this.chart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}",
        },
        grid: {
          right: "10%",
          top: "10%",
          bottom: "10%",
          width: "30%",
        },
        xAxis: {
          type: "value",
          position: "top",
          boundaryGap: [0, 0.1],
          axisLine: {
            lineStyle: {
              color: "#aaa",
            },
          },
          show: false, // 隐藏横轴
        },
        yAxis: {
          type: "category",
          data: countries,
          inverse: true,
          axisLine: {
            lineStyle: {
              color: "#aaa",
            },
          },
          axisTick: {
            show: false,
          },
          show: false, // 隐藏纵轴
        },
        geo: {
          map: "world",
          roam: true, // 可缩放平移
          left: "5%", // 将地图向左移动，增加右侧的空间
          right: "40%", // 增加与右侧条形图的间距
          emphasis: {
            label: {
              show: false,
            },
            itemStyle: {
              areaColor: "#FFD700",
            },
          },
          regions: sortedMapData.map((item, index) => ({
            name: item.name,
            itemStyle: {
              areaColor: colors[index % colors.length], // 依次循环使用颜色
              borderColor: "#333",
            },
          })),
        },
        series: [
          {
            name: "World Map",
            type: "map",
            map: "world",
            geoIndex: 0,
            emphasis: {
              label: {
                show: false,
              },
            },
            data: sortedMapData,
          },
          {
            name: "Data Bar",
            type: "bar",
            encode: {
              x: "value",
              y: "name",
            },
            label: {
              show: true,
              position: "right",
              formatter: (params) => {
                return `${params.data.showName}：${params.data.value}`; // 分两行显示
              },
              lineHeight: 8, // 设置每行的高度
            },
            itemStyle: {
              color: (params) => colors[params.dataIndex % colors.length], // 对应颜色
            },
            barWidth: "50%", // 设置柱状图的宽度
            data: sortedMapData,
          },
        ],
      });
    },
  },
};
</script>
