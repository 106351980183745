<template>
  <div class="video-settings">
    <el-form :model="settings" :rules="rules" ref="settingsForm" label-width="120px" label-position="left">
      <!-- Logo 上传 -->
      <el-form-item label="Logo">
        <!-- <el-upload class="upload-demo" action="" :file-list="fileList" list-type="picture-card" :limit="1"
          :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange">
          <i class="el-icon-plus"></i>
        </el-upload> -->
        <imageUpload v-model="fileList" :limit="5" />
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>

      <!-- 背景颜色 -->
      <el-form-item label="背景颜色" prop="bgColor">
        <div class="color-picker-container" @click="showColorPicker">
          <el-color-picker ref="colorPicker" v-model="settings.bgColor" :predefine="predefinedColors" />
          <span class="ml-10">{{ settings.bgColor || '#ffffff' }}</span>
        </div>
      </el-form-item>

      <!-- 背景音乐音量 -->
      <el-form-item label="背景音乐音量" prop="bgMusicVolume">
        <el-slider v-model="settings.bgMusicVolume" :max="100" :min="0" show-input style="width: 50%;"/>
        <!-- <span>{{ settings.bgMusicVolume }}%</span> -->
      </el-form-item>

      <!-- 视频音色 -->
      <el-form-item label="视频音色" prop="voiceType">
        <el-radio-group v-model="settings.voiceType">
          <el-radio label="男声">男声</el-radio>
          <el-radio label="女声">女声</el-radio>
          <el-radio label="童声">童声</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- 存储位置 -->
      <el-form-item label="存储位置">
        <el-select v-model="settings.storageLocation" placeholder="请选择存储位置">
          <el-option v-for="item in storageOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>

      <!-- 提交按钮 -->
      <el-form-item>
        <el-button type="primary" @click="handleSubmit">保存设置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getAiSetting } from '../../api/ai/aiSetting';
import { listProductType } from '../../api/ai/productType';

export default {
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      settings: {
        logo: null,
        bgColor: '#ffffff',
        bgMusicVolume: 50,
        voiceType: '',
        storageLocation: ''
      },
      storageOptions: [
        { value: 'location1', label: '位置1' },
        { value: 'location2', label: '位置2' },
        { value: 'location3', label: '位置3' }
      ],
      rules: {
        bgColor: [
          { required: true, message: '请选择背景颜色', trigger: 'change' }
        ],
        bgMusicVolume: [
          { required: true, message: '请设置背景音乐音量', trigger: 'change' }
        ],
        voiceType: [
          { required: true, message: '请选择视频音色', trigger: 'change' }
        ]
      },
      predefinedColors: ['#ffffff', '#000000', '#0000ff', '#ffff00', '#ff0000']
    };
  },
  created() {
    this.getSetting();
    this.getProductType()
  },
  methods: {
    //获取设置信息
    getSetting() {
      getAiSetting().then(response => {
        console.log(response)
        this.settings = response.data;
      })
        .catch(error => {
          console.log(error)
        });
    },
    //存储位置管理
    getProductType() {
      listProductType().then(response => {
        console.log(response)
      })
        .catch(error => {
          console.log(error)
        })
    },
    // 选择背景颜色
    handleColorSelect(color) {
      this.settings.bgColor = color;
    },
    // 显示颜色选择器
    showColorPicker() {
      this.$refs.colorPicker.$el.click();
    },
    // showColorPicker() {
    //   this.$refs.colorPicker.showPicker();
    // },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
      if (file.status === 'success') {
        this.settings.logo = file.url;
      }
    },
    handleSubmit() {
      this.$refs.settingsForm.validate((valid) => {
        if (valid) {
          console.log('Submitted settings:', this.settings);
          // 提交逻辑
        } else {
          console.error('Validation failed');
        }
      });
    }
  }
};
</script>

<style scoped>
.video-settings {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}
.color-picker-container {
  width: 130px;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  position: relative;
}

/* .color-picker-container .el-input {
  pointer-events: none;
}

.color-picker-container .el-color-picker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
} */

.ml-10 {
  margin-left: 10px;
}
</style>
